import { getRequest, postRequest, putRequest, deleteRequest } from './client';
import { CurrentUser, Meeting, ID, Analitics, Terms } from 'store/models';

interface userJoinResponse {
  user: CurrentUser;
  token: string;
  mediaMeta: {
    token: string;
  };
}

export function userJoin(params: { email: string; name: string }): Promise<userJoinResponse> {
  return postRequest('users', params);
}

export function userJoinViaDirect(params: {
  access_token: string;
  refresh_token: string;
  instance_url: string;
}): Promise<userJoinResponse> {
  return postRequest('users/sf/direct', params);
}

export function userProfile(): Promise<{ user: CurrentUser }> {
  return getRequest('profile');
}

interface joinRoomResponse {
  meet: Meeting;
  mediaMeta: {
    token: string;
  };
}

export function joinRoom({ room }: { room: string }): Promise<joinRoomResponse> {
  return postRequest(`meet/${room}/join`);
}

interface RecorderPayload {
  room: string;
  token: string;
}

export function joinRoomAsRecorder({ room, token }: RecorderPayload): Promise<joinRoomResponse> {
  return postRequest(`meet/${room}/join_as_recorder?token=${token}`);
}

export function checkIsRecording({
  room,
  token,
}: RecorderPayload): Promise<{ isRecording: boolean }> {
  return postRequest(`meet/${room}/check_is_recording?token=${token}`);
}

export function loadRoom({ room }: { room: string }): Promise<{ meet: Meeting }> {
  return getRequest(`meet/${room}`);
}

export function joinSDRoom({
  room,
}: {
  room: string;
}): Promise<{ meet: Meeting; meta: { sessionId: ID } }> {
  return getRequest(`meet/${room}/sd`);
}

export function sendAnalytic({
  meetId,
  ...params
}: {
  events: Analitics[];
  meetId: ID;
  sessionId?: ID;
}): Promise<void> {
  return postRequest(`analytics/${meetId}`, params);
}

export function sendLogs(params: { logs: any[] }): Promise<void> {
  return postRequest('logs', params);
}

export function getTermsByMeet({ roomId }: { roomId: string }): Promise<Terms> {
  return getRequest(`terms/${roomId}/by_meet`);
}

const bridgeRequest =
  (requester: typeof getRequest) =>
  ({ meetId, payload }: { meetId: ID; payload: string }): Promise<{ data: any }> =>
    requester(`meet/${meetId}/bridge`, { payload });

export const bridgeCreate = bridgeRequest(postRequest);
export const bridgeQuery = bridgeRequest(getRequest);
export const bridgeUpdate = bridgeRequest(putRequest);
export const bridgeDelete = bridgeRequest(deleteRequest);
