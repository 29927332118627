export const prevent = (cb: (e: any) => any) => (e: any) => {
  e.preventDefault();

  cb && cb(e);
};

export const stop = (cb: (e: any) => any) => (e: any) => {
  e.stopPropagation();

  cb && cb(e);
};

export const targetValue =
  (cb: (e: string) => any) => (event: { currentTarget: HTMLInputElement }) => {
    cb && cb(event.currentTarget.value);
  };
