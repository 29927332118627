import { useCallback, useEffect, useRef } from 'react';

const defaultRefFunction = () => {
  throw new Error('Cannot call an event handler while rendering.');
};

export default function useEventCallback<T extends Function>(
  fn: T,
  dependencies: Array<any> = [],
): T {
  const ref = useRef<T | any>(defaultRefFunction);

  useEffect(() => {
    ref.current = fn;
    // React's array spread caution =\
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fn, ...dependencies]);

  return useCallback<any>(
    (...args: any[]) => {
      const fn = ref.current;

      return fn(...args);
    },
    [ref],
  );
}
