import { useRef } from 'react';
import cn from 'classnames';
import { useDescribePublicAppSdk } from 'sdk';

import { ID, Meeting, isPresentation } from 'store/models';
import { useEvent } from 'hooks/socket';
import { MediaTrack } from 'hooks/jitsi/types';
import { useAnalytic } from 'hooks/analytic';
import useEventHandler from 'hooks/useEventHandler';
import Canvas from './Canvas';
import VideoCanvas from './VideoCanvas';
import Placeholder from './Placeholder';
import {
  useCurrentPresent,
  useLayoutToolbar,
  useVotings,
  usePresentAnalytics,
} from './layoutHooks';

import styles from './PresenterLayout.module.scss';

import HeaderToolbar from './HeaderToolbar';

interface ViewerLayoutProps {
  meet: Meeting;
  currentSlideId: ID | null;
  currentPresentationId: ID | null;
  setPresentInfo: (presentId: ID, slideId: ID) => void;
  screenShare?: MediaTrack;
  isPointer: boolean;
}

const defaultBridgeFn = () => {
  process.env.NODE_ENV === 'development' && console.log('Method will be triggered on presenter!');
};

const ViewerLayout: React.FC<ViewerLayoutProps> = ({
  meet,
  currentSlideId,
  currentPresentationId,
  setPresentInfo,
  screenShare,
  isPointer,
}) => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const trackEvent = useAnalytic();
  const { currentPresentation, currentSlide, currentSlides } = useCurrentPresent({
    detailingEnv: 'rd',
    meet,
    currentPresentationId,
    currentSlideId,
  });
  const { isFullscreenEnabled, isFullscreen, onFullscreen, zoom, setZoom } = useLayoutToolbar({
    layoutRef,
  });

  const { onFrameClick } = usePresentAnalytics({
    currentPresentationId,
    currentSlideId,
  });

  const getCurrentState = useEventHandler(
    () => ({
      meet,
      currentPresentation,
      currentSlides: currentSlides || [],
    }),
    [currentSlides, meet, currentPresentation],
  );

  useDescribePublicAppSdk({
    getCurrentState,
    setSlide: defaultBridgeFn,
    setPresent: defaultBridgeFn,
    goToSlide: defaultBridgeFn,
    goNextSlide: defaultBridgeFn,
    goBackSlide: defaultBridgeFn,
  });

  useEvent('meet-slide', ({ slideId, presentationId }: { slideId: ID; presentationId: ID }) => {
    setPresentInfo(presentationId, slideId);

    trackEvent({
      event: 'slide_view',
      presentationId,
      slideId,
      payload: {
        fromSlideId: currentSlideId,
        fromPresentationId: currentPresentationId,
      },
    });
  });

  const { voteTo, handleVote } = useVotings({
    cacheKey: meet._id,
    currentPresentationId,
    currentSlideId,
  });

  return (
    <div className={styles.layout} ref={layoutRef}>
      <header className={cn(styles.header, styles.headerFullscreen)}>
        <HeaderToolbar
          isLikes={meet.isLikes}
          isShowFullscreen={isFullscreenEnabled}
          onFullscreen={onFullscreen}
          isFullscreen={isFullscreen}
          zoom={zoom}
          setZoom={setZoom}
          onVote={handleVote}
          voteTo={voteTo}
        />
      </header>
      {screenShare ? (
        <VideoCanvas video={screenShare} zoom={zoom} />
      ) : !meet.presentations.length ? (
        <Placeholder />
      ) : (
        currentPresentation &&
        currentSlide && (
          <Canvas
            slide={currentSlide}
            isPointer={isPointer}
            title={currentPresentation.name}
            zoom={zoom}
            orientation={
              isPresentation(currentPresentation) ? currentPresentation.orientation : undefined
            }
            onFrameClick={onFrameClick}
          />
        )
      )}
    </div>
  );
};

export default ViewerLayout;
