import callbackify from './callbackify';

const ERROR_STR = 'not implemented';

export default function notImplemented(name: string) {
  return callbackify(() => {
    console.log(`${name}: Method not implemented`);

    return ERROR_STR;
  });
}
