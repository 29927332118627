export interface Dimentions {
  height: number;
  width: number;
}

export function rectToRect(wrapperDimention: Dimentions, frameDimention: Dimentions): number {
  const { height: wrapperHeight, width: wrapperWidth } = wrapperDimention;
  const { height: frameHeight, width: frameWidth } = frameDimention;
  const frameAspect = frameHeight / frameWidth;
  const wrapperAspect = wrapperHeight / wrapperWidth;

  if (wrapperAspect < frameAspect) {
    return wrapperHeight / frameHeight;
  } else {
    return wrapperWidth / frameWidth;
  }
}
