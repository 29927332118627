const colors: string[] = ['#A81897', '#058541', '#1F73B7', '#C72A1C', '#ED961C', '#5D7DF5'];

const NUM_OF_TILE_COLORS = colors.length;

export default function colorFromId(id: string): string {
  const str = id.replace(/\D+/g, '');
  const intId = str.length > 0 ? parseInt(str, 10) : 0;
  const color = Math.abs(intId % NUM_OF_TILE_COLORS);

  return colors[color];
}
