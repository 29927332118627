import { useRef, useCallback, useLayoutEffect } from 'react';

export default function useBottomScroll(scrollRef: React.RefObject<Element>, trigger: any) {
  const firstRenderRef = useRef(true);

  const scrollTo = useCallback(
    (val, animated = true) => {
      const scrollEl = scrollRef.current;

      if (!scrollEl) return;

      if (!animated || !scrollEl.scrollTo) {
        scrollEl.scrollTop = val;

        return;
      }

      scrollEl.scrollTo({
        top: val,
        behavior: 'smooth',
      });
    },
    [scrollRef],
  );

  useLayoutEffect(() => {
    const scrollEl = scrollRef.current;
    const firstRender = firstRenderRef.current;

    scrollTo(scrollEl?.scrollHeight, !firstRender);

    if (firstRender) {
      firstRenderRef.current = false;
    }
  }, [scrollTo, scrollRef, trigger]);
}
