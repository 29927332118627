import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';

export type ButtonProps = {
  className?: string;
  isBlock?: boolean;
  isLoading?: boolean;
  isRounded?: boolean;
  isDisabled?: boolean;
  isBordered?: boolean;
  color?: 'primary' | 'secondary' | 'theme' | 'attention' | 'simple' | 'dark';
  size?: 'xs' | 'sm' | 'xsm' | 'md' | 'lg';
  variant?: 'thin';
};

type RefButtonProps = React.ComponentPropsWithoutRef<'button'> &
  ButtonProps & {
    component?: 'button';
  };

type RefAnchorProps = React.ComponentPropsWithoutRef<'a'> &
  ButtonProps & {
    component: 'a';
  };

type RefAnyButtonProps = ButtonProps & {
  component: React.ElementType;
};

type PolymorphicProps = RefButtonProps | RefAnchorProps | RefAnyButtonProps;

type PolymorphicButton = {
  (props: RefAnchorProps): JSX.Element;
  (props: RefButtonProps): JSX.Element;
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, PolymorphicProps>(
  (
    {
      component: Component = 'button',
      className,
      isBlock = false,
      isLoading = false,
      isRounded = false,
      isBordered = false,
      variant,
      isDisabled,
      color = 'primary',
      size = 'md',
      ...props
    },
    ref,
  ) => {
    const elementProps = {
      disabled: isDisabled,
      className: cn(
        styles.button,
        styles[`button--${color}`],
        styles[`button--${size}`],
        variant && styles[`button--${variant}`],
        {
          [styles.isBlock]: isBlock,
          [styles.isRounded]: isRounded,
          [styles.isBordered]: isBordered,
          [styles.isLoading]: isLoading,
        },
        className,
      ),
      ...props,
    };

    switch (Component) {
      case 'a':
        return (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            {...(elementProps as React.ComponentPropsWithoutRef<'a'>)}
            ref={ref as React.RefObject<HTMLAnchorElement>}
          />
        );

      case 'button':
        return (
          <button
            {...(elementProps as React.ComponentPropsWithoutRef<'button'>)}
            ref={ref as React.RefObject<HTMLButtonElement>}
          />
        );

      default:
        return <Component {...elementProps} ref={ref as React.RefObject<typeof Component>} />;
    }
  },
) as PolymorphicButton;

export default Button;
