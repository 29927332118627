import cn from 'classnames';

import { ReactComponent as PolygonIcon } from 'assets/PolygonIcon.svg';

import styles from './HideButton.module.scss';

interface HideButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  className?: string;
  isOpen: boolean;
  color?: 'dark' | 'light';
  direction?: 'top' | 'bottom' | 'left' | 'right';
}

const HideButton: React.FC<HideButtonProps> = ({
  className,
  isOpen = false,
  color = 'dark',
  direction = 'top',
  ...props
}) => {
  return (
    <button
      className={cn(
        styles.button,
        styles[`button--${color}`],
        styles[`btnDirection--${direction}`],
        !isOpen && styles.isClose,
        className,
      )}
      {...props}
    >
      <PolygonIcon className={styles.buttonIcon} />
    </button>
  );
};

export default HideButton;
