import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { LabledInput, InputErrors } from 'ui/Input';
import Button from 'ui/Button';
import Checkbox from 'ui/Checkbox';
import Spinner from 'ui/Spinner';
import LoginLayout from 'components/LoginLayout';
import { targetValue, prevent } from 'utils/events';
import { Meeting, Terms as TermsModel } from 'store/models';
import Terms from 'components/Terms';
import { loadRoom, getTermsByMeet } from 'api';
import useValidator, { required, email as vEmail, accepted } from 'use-validatorjs';

import textStyles from 'ui/Text.module.scss';
import styles from './Pages.module.scss';
import formStyles from 'ui/Form.module.scss';

const { REACT_APP_API_ENDPOINT } = process.env;

interface LoginProps {
  onUser: (e: { email: string; name: string }) => void;
  entityType?: 'meeting' | 'room';
  entityId?: string;
}

const Login: React.FC<LoginProps> = ({ onUser, entityType, entityId }) => {
  const { t } = useTranslation('joinPage');
  const { t: presT } = useTranslation('presentationPage');
  const { t: vT } = useTranslation('validations');

  const [terms, setTerms] = useState<TermsModel>();
  const [meet, setMeet] = useState<Meeting>();
  const [name, setName] = useState('');
  const [isAcceptTerms, setAccept] = useState(false);
  const [error, setError] = useState<'404' | 'unhandled'>();
  const [email, setEmail] = useState('');
  const { isErrors, showErrors, isShowErrors, errors } = useValidator(
    vT,
    {
      isAcceptTerms,
      email,
      name,
    },
    {
      isAcceptTerms: terms ? accepted : [],
      email: [...(meet?.isGuestEmailRequired ?? false ? [required] : []), vEmail],
      name: required,
    },
  );

  const handleSubmit = useCallback(() => {
    if (isErrors) {
      showErrors();

      return;
    }

    onUser({
      name,
      email,
    });
  }, [name, email, onUser, isErrors, showErrors]);

  const sfLoginUrl = useMemo(() => {
    let href = `${REACT_APP_API_ENDPOINT}/users/sf?`;

    if (entityType) {
      href += `entityType=${entityType}&entityId=${entityId}&`;
    }

    href += `cbUrl=${window.location.origin}${window.location.pathname}`;

    return href;
  }, [entityType, entityId]);

  useEffect(() => {
    if (!entityId) {
      return;
    }

    Promise.allSettled([loadRoom({ room: entityId }), getTermsByMeet({ roomId: entityId })])
      .then(([roomRes, termsRes]) => {
        if (roomRes.status === 'rejected') {
          throw roomRes.reason;
        }

        if (termsRes.status === 'fulfilled') {
          setTerms(termsRes.value);
        }

        const {
          value: { meet },
        } = roomRes;

        setError(undefined);
        setMeet(meet);
      })
      .catch((err) => {
        switch (err.status) {
          case 404: {
            setError('404');
            break;
          }

          default: {
            setError('unhandled');
          }
        }
      });
  }, [entityId]);

  return (
    <LoginLayout
      isBackButton
      isWide={!!terms}
      heading={
        <div className={styles.heading}>
          <Button size="sm" onClick={() => (window.location.href = sfLoginUrl)}>
            <Trans i18nKey="loginButton" t={t} components={{ b: <b /> }} />
          </Button>
        </div>
      }
    >
      {meet ? (
        <form className={formStyles.form} onSubmit={prevent(handleSubmit)}>
          <div className={styles.welcome}>
            <h1 className={styles.welcomeHeading}>{t('title')}</h1>
            <p className={styles.welcomeDetails}>{t('details')}</p>
          </div>
          <div className={styles.loginFromGrid}>
            <div className={styles.loginFromGridColumn}>
              <LabledInput
                autoFocus
                tabIndex={1}
                className={formStyles.formGroup}
                placeholder={t('nameInput.placeholder')}
                value={name}
                onInput={targetValue(setName)}
                errors={errors.name}
              >
                {t('nameInput.label')}
              </LabledInput>
              <LabledInput
                tabIndex={2}
                className={formStyles.formGroup}
                placeholder={t('emailInput.placeholder')}
                value={email}
                onInput={targetValue(setEmail)}
                errors={errors.email}
              >
                {t('emailInput.label')}
              </LabledInput>
            </div>
            {terms && (
              <div className={styles.loginFromGridColumn}>
                <Terms className={styles.terms} terms={terms} />
                <Checkbox value={isAcceptTerms} onChange={setAccept}>
                  {t('terms')}
                </Checkbox>
                <InputErrors errors={errors.isAcceptTerms} />
              </div>
            )}
          </div>
          <Button
            isBlock
            className={terms ? styles.welcomeJoinButton : undefined}
            isDisabled={isErrors && isShowErrors}
            onClick={prevent(handleSubmit)}
          >
            {t('submit')}
          </Button>
        </form>
      ) : !error ? (
        <Spinner isCenter />
      ) : (
        <div className={textStyles.center}>
          <h2>{presT(`errors.${error}`)}</h2>
        </div>
      )}
    </LoginLayout>
  );
};

export default Login;
