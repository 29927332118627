export const copy = (value: string) => {
  const textArea = document.createElement('textarea');

  Object.assign(textArea.style, {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '2em',
    height: '2em',
    padding: 0,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    background: 'transparent',
  });

  textArea.value = value;

  document.body.appendChild(textArea);

  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
};
