import { createContext } from 'react';

import { JitsiRoom } from './types';

export const RoomContext = createContext<JitsiRoom | null>(null);

interface ProviderProps {
  room: JitsiRoom | null;
}

const Provider: React.FC<ProviderProps> = ({ room, children }) => {
  return <RoomContext.Provider value={room}>{children}</RoomContext.Provider>;
};

export default Provider;
