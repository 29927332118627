import { forwardRef, useState, useCallback } from 'react';
import cn from 'classnames';
import InputMask from 'react-input-mask';

import { useLabelId } from '../hooks';

import styles from './Input.module.scss';

interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  color?: 'default' | 'error' | 'secondary';
  isError?: boolean;
  mask?: string | Array<string | RegExp>;
  scale?: 'sm' | 'md';
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      color = 'default',
      isError = false,
      mask,
      scale = 'md',
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setFocused] = useState(false);

    const handleFocus = useCallback(
      (e) => {
        setFocused(true);
        onFocus && onFocus(e);
      },
      [setFocused, onFocus],
    );

    const handleBlur = useCallback(
      (e) => {
        setFocused(false);
        onBlur && onBlur(e);
      },
      [setFocused, onBlur],
    );

    const Component = mask ? InputMask : 'input';

    return (
      <div
        className={cn(
          styles.wrapper,
          styles[`wrapper--${scale}`],
          styles[isError ? 'wrapper--error' : `wrapper--${color}`],
          {
            [styles.isFocused]: isFocused,
          },
          className,
        )}
      >
        <Component
          ref={ref}
          mask={mask as string}
          className={styles.input}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
      </div>
    );
  },
);

interface LabledInputProps extends InputProps {
  errors?: string[];
}

export const LabledInput: React.FC<LabledInputProps> = ({
  children,
  className,
  errors,
  ...props
}) => {
  const id = useLabelId('input');

  return (
    <div className={className}>
      <label htmlFor={id} className={styles.label}>
        {children}
      </label>
      <Input id={id} isError={(errors?.length ?? 0) > 0} {...props} />
      <InputErrors errors={errors} />
    </div>
  );
};

interface InputErrorsProps {
  errors?: string[];
}

export const InputErrors: React.FC<InputErrorsProps> = ({ errors }) => {
  if (errors) {
    return <div className={styles.errors}>{errors}</div>;
  }

  return null;
};

export default Input;
