import { useEffect } from 'react';
import { once, off } from 'utils/dom';
import NoSleep from 'nosleep.js';

let noSleep: NoSleep;

export default function useAwake(isEnabled: boolean = true) {
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    if (!noSleep) {
      noSleep = new NoSleep();
    }

    const handler = once(document, 'click', () => {
      noSleep.enable();
    });

    return () => {
      off(document, 'click', handler);
      noSleep.disable();
    };
  }, [isEnabled]);
}
