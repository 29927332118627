export function download(filename: string, file: Blob) {
  const elem = document.createElement('a');

  elem.setAttribute('download', filename);
  elem.setAttribute('href', URL.createObjectURL(file));

  elem.style.display = 'none';
  document.body.appendChild(elem);

  elem.click();

  document.body.removeChild(elem);
}
