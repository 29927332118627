export type ID = string;
export type Timestamp = string;

interface Timestamps {
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface Terms extends Timestamps {
  orgId: string;
  isActive: boolean;
  terms: {
    [key: string]: string;
  };
}

export interface User extends Timestamps {
  _id: ID;
  isAnonymous: boolean;
  name: string;
  email: string;
}

export interface Comment extends Timestamps {
  _id: ID;
  text: string;
}

export interface CurrentUser extends User {
  nickname: string;
  locale: string;
  organization_id: ID;
}

export interface Message extends Timestamps {
  _id: ID;
  distingusher?: string;
  author: User;
  text: string;
}

export interface Analitics {
  event:
    | 'slide_view'
    | 'dislike'
    | 'like'
    | 'unlike'
    | 'undislike'
    | 'click'
    | 'video'
    | 'audio'
    | 'desktop'
    | 'full_screen_conference'
    | 'js_bridge'
    | 'js_bridge_slide_view'
    | 'buttons_slide_view'
    | 'transfer_control';
  presentationId?: ID;
  slideId?: ID;
  payload: { [key: string]: any };
}

export interface Slide {
  _id: ID;
  externalId: ID;
  parentPresentationId: ID | null;
  url: string;
  title?: string;
  order?: number;
  branch?: string;
  preview: {
    small: string;
    big: string;
  };
}

export interface PresentationAnalitics {
  slideDuration: boolean;
  slideViews: boolean;
  totalDuration: boolean;
  slideClicks: boolean;
  slideNavigation: boolean;
  votings: boolean;
}

interface BasePresentation extends Timestamps {
  _id: ID;
  name: string;
  slides: Slide[];
  orgId: String;
}

export interface Presentation extends BasePresentation {
  externalId: ID;
  pdfLink?: string;
  alias?: string;
  analytics: PresentationAnalitics;
  orientation: 'horizontal' | 'vertical';
  scenarios: PresentationScenario[];
}

export interface PresentationScenario extends BasePresentation {
  scenarioId: ID;
  presentation: ID;
  isFavorite: boolean;
  isCombined: boolean;
}

export function isPresentation(pres: any): pres is Presentation {
  return 'scenarios' in pres;
}

export function isPresentationScenario(pres: any): pres is PresentationScenario {
  return 'scenarioId' in pres;
}

export type CurrentPresentation = Presentation | PresentationScenario;

export interface MeetingConfig {
  isVideo: boolean;
  isRecords: boolean;
  isChat: boolean;
  isChatHistory: boolean;
  isDetailedStats: boolean;
  isCallRepeat: boolean;
  sessionTimeout: number;
}

export interface Meeting extends Timestamps {
  _id: ID;
  orgLogo?: string;
  visitId: ID;
  meetId: string;
  isLikes: boolean;
  isGuestEmailRequired: boolean;
  startAt: Timestamp;
  finishedAt?: Timestamp;
  presenter?: User;
  presentations: Presentation[];
  config: MeetingConfig;
}

export interface MeetingInfo {
  currentSlideId: ID | null;
  currentPresentationId: ID | null;
  mutedUsers: ID[];
  presentManageUsers: ID[];
  presenterLeaveAt: null | Timestamp;
  isRecording: boolean;
  focusUserId: ID | null;
}

export interface Notification {
  id: ID;
  sound?: string;
  title: React.ReactNode;
  caption?: React.ReactNode;
}
