import { useState, useMemo, useEffect } from 'react';

import { Timestamp } from 'store/models';

export default function useTimer(till: null | Timestamp | Date) {
  const [now, setNow] = useState<Date>(() => new Date());
  const isCrossed = useMemo(() => till !== null && now && now > new Date(till), [now, till]);

  useEffect(() => {
    if (till === null) {
      return;
    }

    setNow(new Date());

    const int = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(int);
    };
  }, [till]);

  return [isCrossed];
}
