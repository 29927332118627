import { useTranslation } from 'react-i18next';

import Dropdown from 'ui/Dropdown';

import styles from './ShareDropdown.module.scss';

import MailIcon from 'assets/MailIcon.png';
import FbIcon from 'assets/FbIcon.png';
import TwitterIcon from 'assets/TwitterIcon.png';

interface Social {
  name: string;
  icon: string;
  getUrl?: (url: string, t: (term: string, payload?: any) => string) => string;
}

const socialSet: Social[] = [
  {
    name: 'fb',
    icon: FbIcon,
    getUrl: (url) => `https://www.facebook.com/sharer/sharer.php?u=${url}`,
  },
  {
    name: 'twitter',
    icon: TwitterIcon,
    getUrl: (url) => `http://twitter.com/share?url=${url}`,
  },
  {
    name: 'mail',
    icon: MailIcon,
    getUrl: (url, t) => `mailto:?to=&subject=${t('mail.subject')}&body=${t('mail.body', { url })}`,
  },
];

interface ShareDropdownProps extends React.ComponentProps<typeof Dropdown> {}

const ShareDropdown: React.FC<ShareDropdownProps> = ({ ...props }) => {
  const { t } = useTranslation('socials');

  return (
    <Dropdown
      frameClassName={styles.dropdown}
      renderDropdown={() => (
        <div>
          <div className={styles.title}>Share this page</div>
          <div className={styles.socials}>
            {socialSet.map((social) => (
              <a
                key={social.name}
                href={social.getUrl ? social.getUrl(window.location.href, t) : `#${social.name}`}
                className={styles.social}
                target="_blank"
                rel="noreferrer nofollow"
                title={t(`${social.name}.title`)}
              >
                <img src={social.icon} alt={t(`${social.name}.title`)} />
              </a>
            ))}
          </div>
        </div>
      )}
      {...props}
    />
  );
};

export default ShareDropdown;
