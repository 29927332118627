import { useRef, useState, useMemo, useCallback } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import cn from 'classnames';

import styles from './Dropdown.module.scss';

interface DropdownProps {
  className?: string;
  frameClassName?: string;
  isActive?: boolean | null;
  setActive?: (e: boolean) => void;
  isCloseOnOutClick?: boolean;
  dropdown?: React.ReactNode;
  renderDropdown?: (e: { onOpen: (val: boolean) => void }) => React.ReactNode;
  render?: (e: {
    isOpen: boolean;
    onOpen: (val: boolean) => void;
    toggleOpen: () => void;
  }) => React.ReactNode;
  position?: 'center' | 'right' | 'left';
  direction?: 'bottom' | 'top';
}

const Dropdown: React.FC<DropdownProps> = ({
  isActive = null,
  setActive,
  children,
  isCloseOnOutClick = true,
  render,
  className,
  frameClassName,
  position = 'center',
  direction = 'bottom',
  renderDropdown,
  dropdown,
}) => {
  const [isOpen, setOpen] = useState(false);
  const frameRef = useRef(null);

  const isDropdownOpen = useMemo(() => (isActive !== null ? isActive : isOpen), [isActive, isOpen]);

  const handleOpen = useCallback(
    (val: boolean) => {
      if (isActive !== null) {
        if (!setActive) {
          throw Error('Dropdown@handleActive: setActive rqeuired prop if isActive passed!');
        }

        setActive(val);

        return;
      }

      setOpen(val);
    },
    [setActive, isActive],
  );

  const toggleOpen = useCallback(() => {
    handleOpen(!isDropdownOpen);
  }, [handleOpen, isDropdownOpen]);

  const onClickOutside = useCallback(() => {
    if (isCloseOnOutClick) {
      handleOpen(false);
    }
  }, [handleOpen, isCloseOnOutClick]);

  useOnClickOutside(frameRef, onClickOutside);

  return (
    <div className={cn(styles.dropdown, className)}>
      {render ? (
        render({
          toggleOpen,
          onOpen: handleOpen,
          isOpen: isDropdownOpen,
        })
      ) : (
        <div onClick={toggleOpen} className={styles.dropdownTrigger}>
          {children}
        </div>
      )}
      {isDropdownOpen && (
        <div
          ref={frameRef}
          className={cn(
            styles.dropdownFrame,
            styles[`dropdownFrame--${position}`],
            styles[`dropdownFrame--${direction}`],
            frameClassName,
          )}
        >
          {renderDropdown ? renderDropdown({ onOpen: handleOpen }) : dropdown}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
