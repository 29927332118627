import cn from 'classnames';

import Slider from 'ui/Slider';
import Dropdown from 'ui/Dropdown';
import Voting from './Voting';

import styles from './PresenterLayout.module.scss';

import { ReactComponent as ZoomIcon } from 'assets/ZoomIcon.svg';
import { ReactComponent as FullscreenIcon } from 'assets/FullscreenIcon.svg';

interface HeaderToolbarProps {
  className?: string;
  isLikes?: boolean;
  isFullscreen: boolean;
  isShowFullscreen: boolean;
  onFullscreen?: () => any;
  setZoom: (zoom: number) => any;
  zoom: number;
  onVote?: (e: 'like' | 'dislike' | undefined) => any;
  voteTo?: 'like' | 'dislike';
}

const MIN_ZOOM = 1;
const MAX_ZOOM = 3;

const HeaderToolbar: React.FC<HeaderToolbarProps> = ({
  className,
  isLikes = false,
  onFullscreen,
  isFullscreen,
  isShowFullscreen = false,
  setZoom,
  zoom,
  onVote,
  voteTo,
}) => {
  return (
    <div className={cn(styles.headerRight, className)}>
      <Dropdown
        className={cn(styles.headerActionElement, styles.headerSelect)}
        dropdown={
          <div className={styles.dropdownMenu}>
            <Slider
              isVertical
              from={MIN_ZOOM}
              to={MAX_ZOOM}
              value={zoom}
              onChange={setZoom}
              before={`${MIN_ZOOM * 100} %`}
              after={`${MAX_ZOOM * 100} %`}
            />
          </div>
        }
        render={({ toggleOpen }) => (
          <button onClick={toggleOpen} className={styles.headerActionButton}>
            <ZoomIcon />
          </button>
        )}
      />
      {isLikes && <Voting onVote={onVote} voteTo={voteTo} />}
      {isShowFullscreen && (
        <button
          className={cn(styles.headerActionButton, styles.headerActionElement)}
          onClick={onFullscreen}
        >
          <FullscreenIcon className={cn(isFullscreen && styles.headerFullscreenIconActive)} />
        </button>
      )}
    </div>
  );
};

export default HeaderToolbar;
