import { useMemo } from 'react';
import cn from 'classnames';

import Select from 'ui/Select';
import {
  ID,
  Presentation,
  PresentationScenario,
  CurrentPresentation,
  isPresentationScenario,
} from 'store/models';

import styles from './PresenterLayout.module.scss';

import { ReactComponent as MenuIcon } from 'assets/MenuIcon.svg';

interface PresentSelectProps
  extends Omit<React.ComponentProps<typeof Select>, 'getItem' | 'list' | 'onChange' | 'value'> {
  presentations: Presentation[];
  currentPresentation?: CurrentPresentation;
  setCurrentPresent: (id: ID) => void;
  variant?: 'dark' | 'light';
}

const PresentSelect: React.FC<PresentSelectProps> = ({
  presentations,
  currentPresentation,
  setCurrentPresent,
  position = 'left',
  variant = 'dark',
  ...props
}) => {
  const flatPresentations = useMemo(
    () =>
      presentations.reduce<Array<PresentationScenario | Presentation>>((acc, item) => {
        acc.push(item);

        if (item.scenarios.length) {
          acc.push(...item.scenarios);
        }

        return acc;
      }, []),
    [presentations],
  );

  return (
    <Select
      className={cn(styles.headerActionElement, styles.headerSelect)}
      frameClassName={styles.burgerMenu}
      list={flatPresentations}
      getItem={(item) => (
        <span className={cn(isPresentationScenario(item) && styles.presentationScenario)}>
          {item.name}
        </span>
      )}
      value={currentPresentation}
      onChange={(pres) => setCurrentPresent(pres._id)}
      position={position}
      render={({ isOpen, toggleOpen }) => (
        <button
          onClick={toggleOpen}
          className={cn(
            styles.headerActionButton,
            styles.headerBurgerButton,
            styles[`headerBurgerButton--${variant}`],
            isOpen && styles.headerActionButtonActive,
          )}
        >
          <MenuIcon />
        </button>
      )}
      {...props}
    />
  );
};

export default PresentSelect;
