import { useState, useRef, useLayoutEffect } from 'react';

export function useLabelId(prefix: string) {
  const [id] = useState(() => `${prefix}-${Math.random()}-${Math.random()}`);

  return id;
}

const poralId = `layout-portal-${Math.random()}`;

export const PortalTarget = () => <div id={poralId} />;

export function useLayoutTarget() {
  const layoutRef = useRef<HTMLElement>();

  useLayoutEffect(() => {
    const modal = document.getElementById(poralId);

    if (!modal) {
      throw new Error('PortalTarget should be mounted before usage!');
    }

    layoutRef.current = modal;
  }, []);

  return layoutRef;
}
