export default function callbackify<T extends (...args: any[]) => any>(fn: T) {
  const fnLength = fn.length;

  return function (this: any, ...args: Parameters<T>): ReturnType<T> {
    if (args.length === fnLength + 1 && typeof args[fnLength] === 'function') {
      const cb: (err: null | any) => any = args.pop();
      const res = fn.apply(this, args);

      if (!(res instanceof Promise)) {
        return cb(res);
      }

      res.then(
        (val: any) => {
          cb(val);
        }, // Legacy callback api. Should be an error as first argument
        (err: any) => {
          cb(err);
        },
      );

      // @ts-ignore: Legacy callback mode. Inside app should be used Promise only.
      return;
    }

    return fn.apply(this, args);
  };
}
