import { useRef, useState, useLayoutEffect, useCallback, useMemo } from 'react';
import cn from 'classnames';
import useDrags from 'use-drags';

import styles from './Slider.module.scss';

interface SliderProps {
  className?: string;
  isVertical?: boolean;
  value: number;
  onChange: (e: number) => any;
  from?: number;
  to?: number;
  before?: React.ReactNode;
  after?: React.ReactNode;
}

const Slider: React.FC<SliderProps> = ({
  className,
  isVertical = false,
  onChange,
  value,
  from = 0,
  to = 1,
  before,
  after,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isFirstRender, setFirstRender] = useState(true);

  const setPosition = useCallback(
    ({ left, width, height, top }, { clientX, clientY }) => {
      let d: number;

      if (isVertical) {
        d = (clientY - top) / height;
      } else {
        d = (clientX - left) / width;
      }

      if (d > 0.45 && d < 0.55) {
        d = 0.5;
      }

      if (d > 0.93) {
        d = 1;
      }

      if (d < 0.09) {
        d = 0;
      }

      const newVal = d * (to - from) + from;

      onChange(Math.max(Math.min(newVal, to), from));
    },
    [onChange, from, to, isVertical],
  );

  useDrags(buttonRef, (ev) => {
    const containerEl = containerRef.current;
    const rect = containerEl?.getBoundingClientRect();

    if (ev.first) {
      ev.el.style.cursor = 'grabbing';
    }

    if (ev.last) {
      ev.el.style.cursor = '';
    }

    setPosition(rect, ev);
  });

  const buttonStyles = useMemo(() => {
    const buttonEl = buttonRef.current;
    const buttonSize = isFirstRender
      ? 0
      : buttonEl?.[isVertical ? 'offsetHeight' : 'offsetWidth'] ?? 0;
    const position = ((value - from) / (to - from)) * 100;

    return {
      [isVertical ? 'top' : 'left']: `calc(${position}% - ${buttonSize / 2}px)`,
    };
  }, [value, to, from, isVertical, isFirstRender]);

  const handleTrackClick = useCallback(
    (ev) => {
      const containerEl = containerRef.current;
      const rect = containerEl?.getBoundingClientRect();

      setPosition(rect, { clientX: ev.clientX, clientY: ev.clientY });
    },
    [setPosition],
  );

  // Trigger render for inital button position
  useLayoutEffect(() => {
    setFirstRender(false);
  }, []);

  return (
    <div className={cn(styles.container, isVertical && styles.isVertical, className)}>
      {before && <div className={styles.before}>{before}</div>}
      <div ref={containerRef} className={styles.track} onClick={handleTrackClick}>
        <div ref={buttonRef} className={styles.button} style={buttonStyles} />
      </div>
      {after && <div className={styles.after}>{after}</div>}
    </div>
  );
};

export default Slider;
