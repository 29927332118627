import { ParsedQs } from 'qs';

interface CookieOptions {
  path?: string;
  expires?: Date | string;
  secure?: boolean;
}

export function setCookie(name: string, value: string, options: CookieOptions = {}) {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  const updatedCookie = Object.entries(options).reduce((acc, [optKey, optValue]) => {
    acc += `; ${optKey}`;

    if (optValue !== true) {
      acc += `=${optValue}`;
    }

    return acc;
  }, `${encodeURIComponent(name)}=${encodeURIComponent(value)}`);

  document.cookie = updatedCookie;
}

export function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function stringyfyQuery(value: string | ParsedQs | ParsedQs[] | string[]) {
  return (Array.isArray(value) ? value[0] : value) as string;
}
