import { useEffect, useRef } from 'react';
import cn from 'classnames';

import styles from './SlidesPreview.module.scss';

interface SlidesPreviewItemProps {
  imgSrc: string;
  title: string;
  isActive: boolean;
  onActive: () => void;
}

const SlidesPreviewItem: React.FC<SlidesPreviewItemProps> = ({
  imgSrc,
  title,
  isActive,
  onActive,
}) => {
  const nodeRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    nodeRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }, [isActive]);

  return (
    <div
      ref={nodeRef}
      className={cn(styles.slide, isActive && styles.slideActive)}
      onClick={() => onActive()}
      title={title}
    >
      <img className={styles.slidePreview} src={imgSrc} alt={title} />
    </div>
  );
};

export default SlidesPreviewItem;
