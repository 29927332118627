import { useRef, forwardRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Comment as CommentModel } from 'store/models';
import ChatInput from './ChatInput';
import Text from './Text';
import useDate from 'hooks/useDate';
import useBottomScroll from 'hooks/useBottomScroll';

import styles from './Comments.module.scss';

interface CommnetProps {
  comment: CommentModel;
  className?: string;
}

const Comment: React.FC<CommnetProps> = ({ comment, className }) => {
  const date = useDate(comment.createdAt);

  return (
    <div className={cn(styles.comment, className)}>
      <div className={styles.commentMeta}>
        <div className={styles.commentDate}>{date}</div>
      </div>
      <div className={styles.commentText}>
        <Text text={comment.text} />
      </div>
    </div>
  );
};

interface CommentsProps {
  onClose: () => void;
  className?: string;
  comments: CommentModel[];
  onAddComment: (e: { text: string }) => void;
}

const Comments = forwardRef<HTMLDivElement, CommentsProps>(
  ({ className, onClose, comments, onAddComment }, ref) => {
    const { t } = useTranslation('comments');
    const scrollEl = useRef<HTMLDivElement>(null);

    useBottomScroll(scrollEl, comments);

    return (
      <div ref={ref} className={cn(styles.comments, className)}>
        <div className={styles.commentsHeading}>
          <h3 className={styles.commentsTitle}>{t('title')}</h3>
          <button className={styles.close} onClick={onClose} />
        </div>
        <div ref={scrollEl} className={styles.commentsList}>
          {comments.map((comment) => (
            <Comment key={comment._id} comment={comment} />
          ))}
        </div>
        <ChatInput
          className={styles.input}
          onMessage={onAddComment}
          placeholder={t('input.placeholder')}
          buttonTitle={t('buttonTitle')}
        />
      </div>
    );
  },
);

export default Comments;
