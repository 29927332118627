import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import LoginLayout from 'components/LoginLayout';
import { LabledInput } from 'ui/Input';
import Button from 'ui/Button';
import { targetValue, prevent } from 'utils/events';

import formStyles from 'ui/Form.module.scss';

interface MeetingProps {
  isEmbed?: boolean;
}

const Meeting: React.FC<MeetingProps> = ({ isEmbed = false }) => {
  const { t } = useTranslation('indexPgae');
  const history = useHistory();

  const [meetId, setMeetId] = useState('');
  const [isInputTouched, setInputTouched] = useState(false);
  const handleSubmit = useMemo(
    () =>
      prevent(() => {
        const meetUrl = `${isEmbed ? '/present/embed/' : '/p/'}${meetId}`;

        history.push(meetUrl);
      }),
    [history, meetId, isEmbed],
  );

  return (
    <LoginLayout isLogo>
      <form className={formStyles.form}>
        <LabledInput
          tabIndex={1}
          autoFocus
          className={formStyles.formGroup}
          isError={isInputTouched && meetId === ''}
          mask="999-9999-999"
          value={meetId}
          onChange={targetValue(setMeetId)}
          onBlur={() => setInputTouched(true)}
          placeholder={t('meetInput.placeholder')}
        >
          {t('meetInput.label')}
        </LabledInput>
        <Button isBlock isDisabled={meetId.replace('_', '').length < 12} onClick={handleSubmit}>
          {t('submit')}
        </Button>
      </form>
    </LoginLayout>
  );
};

export default Meeting;
