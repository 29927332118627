import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Meeting from 'pages/Meeting';
import Present from 'pages/Present';
import SelfDetailing from 'pages/SelfDetailing';
import Recorder from 'pages/Recorder';
import NotFound from 'pages/NotFound';

const RouterBase = () => (
  <Router>
    <Switch>
      <Route path="/present/mobile/:id">
        <SelfDetailing />
      </Route>
      <Route path="/present/embed/:id">
        <Present isPresentOnly />
      </Route>
      <Route path="/present/:id">
        <Present />
      </Route>
      <Route path="/p/:id">
        <Present />
      </Route>
      <Route path="/recorder/:id">
        <Recorder />
      </Route>
      <Route path="/c/:id">
        <Present isConferenceOnly />
      </Route>
      <Route path="/view/:id">
        <SelfDetailing />
      </Route>
      <Route path="/s/:id">
        <SelfDetailing />
      </Route>
      <Route path="/indexIOS.html">
        <Meeting isEmbed />
      </Route>
      <Route path="/embed">
        <Meeting isEmbed />
      </Route>
      <Route exact path="/">
        <Meeting />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Router>
);

export default RouterBase;
