export default class GlobalazableObject {
  _patchGlobal(
    obj: any,
    globalName: string,
    { methods = [], prefix = '' }: { readonly methods?: string[]; prefix?: string } = {},
  ) {
    obj[globalName] = this;

    methods.forEach((key) => {
      obj[`${prefix}${key}`] = (this as any)[key].bind(this);
    });
  }

  _clearGlobal(
    obj: any,
    globalName: string,
    { methods = [], prefix = '' }: { methods?: string[]; prefix?: string } = {},
  ) {
    obj[globalName] = undefined;

    methods.forEach((key) => {
      obj[`${prefix}${key}`] = undefined;
    });
  }
}
