import { useState, useEffect, useCallback, useMemo } from 'react';
import cn from 'classnames';
import useSound from 'use-sound';

import useEventHandler from 'hooks/useEventHandler';

import styles from './Notification.module.scss';

import clickSound from 'assets/click.wav';

interface NotificationProps {
  onClose?: () => void;
  className?: string;
  closeTimeout?: number;
  sound?: string;
  caption?: React.ReactNode;
}

const noop = () => {};

const Notification: React.FC<NotificationProps> = ({
  children,
  className,
  onClose = noop,
  closeTimeout = 5000,
  sound = clickSound,
  caption,
}) => {
  const [isHover, setHover] = useState(false);

  const handleClose = useEventHandler(onClose);

  const notificationStyles = useMemo(
    () => ({
      animationDuration: `${closeTimeout}ms`,
    }),
    [closeTimeout],
  );

  useEffect(() => {
    if (isHover) {
      return;
    }

    const timeout = window.setTimeout(handleClose, closeTimeout);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [isHover, closeTimeout, handleClose]);

  const mouseOn = useCallback(() => {
    setHover(true);
  }, []);

  const mouseOut = useCallback(() => {
    setHover(false);
  }, []);

  const [play] = useSound(sound);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <div
      className={cn(styles.notificaiton, !isHover && styles.isAnimation, className)}
      style={notificationStyles}
      onMouseEnter={mouseOn}
      onMouseLeave={mouseOut}
    >
      <div className={styles.title}>{children}</div>
      {caption && <div className={styles.caption}>{caption}</div>}
      <button className={styles.close} onClick={onClose} />
    </div>
  );
};

export default Notification;
