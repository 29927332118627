import { useRef } from 'react';

import { MediaTrack } from 'hooks/jitsi/types';
import { useRenderTrack } from 'hooks/jitsi';

type UserVideoProps = React.ComponentPropsWithoutRef<'video'> & {
  track: MediaTrack;
};

const UserVideo: React.FC<UserVideoProps> = ({ track, ...props }) => {
  const videoRef = useRef<null | HTMLVideoElement>(null);

  useRenderTrack(videoRef, track);

  return (
    <video
      ref={videoRef}
      autoPlay
      playsInline
      webkit-playsinline="true"
      controls={false}
      muted
      {...props}
    />
  );
};

export default UserVideo;
