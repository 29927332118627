import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import Button from 'ui/Button';

import { User, ID } from 'store/models';
import { getCurrentUser } from 'store/selectors';
import { UserTracks } from 'hooks/jitsi/types';
import ConferenceUser from './ConferenceUser';
import CopyBtn from './CopyBtn';

import styles from './Conference.module.scss';

import { ReactComponent as LikeOnIcon } from 'assets/LikeOnIcon.svg';
import { ReactComponent as LikeOffIcon } from 'assets/LikeOffIcon.svg';

import { ReactComponent as PointerOnIcon } from 'assets/PointerOnIcon.svg';
import { ReactComponent as PointerOffIcon } from 'assets/PointerOffIcon.svg';
import { ReactComponent as CopyIcon } from 'assets/CopyIcon.svg';

interface CallProps {
  meetId: string;
  users: User[];
  focusUserId: ID | null;
  setFocusUserId: (userId: ID | null) => void;
  tracks: { [key: string]: UserTracks };
  isLikes: boolean;
  setLikes: (e: boolean) => any;
  userPointers: Set<ID>;
  setPointer: (userId: ID, e: boolean) => any;
  mutedUsers: Set<ID>;
  setMuted: (userId: ID, e: boolean) => any;
  isSelfPointer: boolean;
  setSelfPointer: (e: boolean) => any;
  isPresenter: boolean;
}

const Call: React.FC<CallProps> = ({
  meetId,
  users,
  focusUserId,
  setFocusUserId,
  tracks,
  isLikes,
  setLikes,
  userPointers,
  mutedUsers,
  setMuted,
  setPointer,
  isPresenter,
  isSelfPointer,
  setSelfPointer,
}) => {
  const { t } = useTranslation('conference');
  const currentUser = useSelector(getCurrentUser);

  const handleFocusId = useCallback(
    (userId: ID, val: boolean) => {
      setFocusUserId(val ? userId : null);
    },
    [setFocusUserId],
  );

  return (
    <>
      <div className={styles.tabContent}>
        {users.map((user) => {
          const isCurrentUser = user._id === currentUser?._id;

          return (
            <ConferenceUser
              key={user._id}
              isCurrentUser={isCurrentUser}
              className={styles.confUser}
              user={user}
              isPresenter={isPresenter}
              isPointer={userPointers.has(user._id)}
              setMuted={setMuted}
              isFocused={focusUserId === user._id}
              setFocus={handleFocusId}
              isMuted={mutedUsers.has(user._id)}
              setPointer={setPointer}
              tracks={isCurrentUser ? tracks.currentUser : tracks[user._id]}
              isAudioTrack={false}
            />
          );
        })}
      </div>
      <div className={styles.footer}>
        {isPresenter && (
          <div className={styles.abilities}>
            <div className={styles.ability}>
              <Button
                isRounded
                isBordered
                size="sm"
                color={isLikes ? 'theme' : 'simple'}
                onClick={() => setLikes(!isLikes)}
              >
                {isLikes ? <LikeOnIcon /> : <LikeOffIcon />}
              </Button>
              <div className={styles.abilityCaption}>{t(`likes.${isLikes ? 'on' : 'off'}`)}</div>
            </div>
            <div className={styles.ability}>
              <Button
                isRounded
                isBordered
                size="sm"
                color={isSelfPointer ? 'theme' : 'simple'}
                onClick={() => setSelfPointer(!isSelfPointer)}
              >
                {isSelfPointer ? <PointerOnIcon /> : <PointerOffIcon />}
              </Button>
              <div className={styles.abilityCaption}>
                {t(`pointer.${isSelfPointer ? 'on' : 'off'}`)}
              </div>
            </div>
          </div>
        )}
        <div className={styles.meetId}>
          <div className={styles.meetIdCaption}>{t('meetId')}</div>
          <CopyBtn content={meetId}>
            {meetId}
            <CopyIcon />
          </CopyBtn>
        </div>
      </div>
    </>
  );
};

export default Call;
