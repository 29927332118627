import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import random from 'lodash/random';
import cn from 'classnames';

import Button from 'ui/Button';

import styles from './LoginLayout.module.scss';

import { ReactComponent as BackIcon } from 'assets/BackIcon.svg';
import Logo from 'assets/logo.png';

interface LoginLayoutProps {
  isBackButton?: boolean;
  isWide?: boolean;
  heading?: React.ReactNode;
  footer?: React.ReactNode;
  isLogo?: boolean;
  customLogo?: string;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({
  isBackButton = false,
  isWide = false,
  heading,
  children,
  footer,
  isLogo = false,
  customLogo,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const bgImage = useMemo(() => {
    const bgIndex = random(1, 30);

    // TODO: check https://github.com/facebook/create-react-app/issues/10088
    return require(`assets/bg/${bgIndex}.jpg`).default;
  }, []);

  return (
    <div className={styles.loginWrapper} style={{ backgroundImage: `url(${bgImage})` }}>
      {heading}
      <div className={cn(styles.loginBlock, isWide && styles.loginBlockWide)}>
        {isBackButton && (
          <Button
            // TODO: should be refactored to RouterLink
            onClick={() => history.push('/')}
            isRounded
            size="sm"
            color="secondary"
            className={styles.backButton}
            title={t('ui:back')}
          >
            <BackIcon />
          </Button>
        )}
        {isLogo && <img className={styles.logo} src={customLogo || Logo} alt={t('ui:logo')} />}
        {children}
      </div>
      {footer}
    </div>
  );
};

export default LoginLayout;
