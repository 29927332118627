import { User } from './models';

export interface State {
  usersById: {
    [key: string]: User;
  };
}

export type Action = {
  type: 'users';
  users: User[];
};

const defaultState: State = {
  usersById: {},
};

function users(state: State = defaultState, action: Action): State {
  switch (action.type) {
    case 'users': {
      return {
        ...state,
        usersById: {
          ...state.usersById,
          ...action.users.reduce((acc: { [key: string]: User }, user) => {
            acc[user._id] = user;

            return acc;
          }, {}),
        },
      };
    }

    default:
      return state;
  }
}

export default users;
