import { ReactNode } from 'react';

type Handler = (str: string) => string | ReactNode;
const urlRegex = /(\b(https?|http):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

export default function parseTextToArray(
  re: RegExp,
  str: string,
  cb: Handler = (str: string) => str,
) {
  const result: Array<string | ReturnType<typeof cb>> = [];
  let tmp: RegExpExecArray | null;
  let lastIndex = 0;
  while ((tmp = re.exec(str)) !== null) {
    const head = str.slice(lastIndex, tmp.index);
    if (head !== '') {
      result.push(head);
    }
    result.push(cb(tmp[0]));
    lastIndex = re.lastIndex;
  }
  const tail = str.slice(lastIndex);
  if (tail !== '') {
    result.push(tail);
  }
  return result;
}

export const parseLinks = parseTextToArray.bind(null, urlRegex);
