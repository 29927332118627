import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getNotifications } from 'store/selectors';
import { AppDispatch } from 'store';
import { ID } from 'store/models';
import Notification from 'ui/Notification';

import styles from './Notifications.module.scss';

const Notifications: React.FC = () => {
  const notifications = useSelector(getNotifications);
  const rootDispatch = useDispatch<AppDispatch>();

  const removeNotification = useCallback(
    (id: ID) => {
      rootDispatch({
        type: 'removeNotification',
        id,
      });
    },
    [rootDispatch],
  );

  return (
    <div className={styles.notifications}>
      {notifications.map((not) => (
        <Notification
          key={not.id}
          className={styles.notification}
          onClose={() => removeNotification(not.id)}
          caption={not.caption}
        >
          {not.title}
        </Notification>
      ))}
    </div>
  );
};

export default Notifications;
