import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import qs from 'qs';

import { joinRoomAsRecorder, checkIsRecording } from 'api';
import useJitsi from 'hooks/jitsi';
import { MediaTrack } from 'hooks/jitsi/types';
import useAudioContext, { useRecordTrack, startRecord, stopRecord } from 'hooks/audio';
import { stringyfyQuery } from 'utils/cookie';

const POLL_INTERVAL = 1000 * 5;

function TrackNode({ track }: { track: MediaTrack }) {
  useRecordTrack(track);

  return null;
}

const Recorder: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [recordState, setRecordState] = useState<'init' | 'error' | 'process' | 'ready'>('init');
  const [mediaConfig, setMediaConfig] = useState<{ roomId: string; token: string | null }>({
    roomId: '',
    token: null,
  });
  const { resume } = useAudioContext();
  const { token, fileName } = useMemo(() => {
    const query = qs.parse(window.location.search.replace(/^\?/, ''));

    return {
      token: query.t ? stringyfyQuery(query.t) : undefined,
      fileName: query.file ? stringyfyQuery(query.file) : undefined,
    };
  }, []);

  const handleStop = useCallback(() => {
    stopRecord();
    setRecordState('ready');
  }, []);

  useEffect(() => {
    if (!token) {
      setRecordState('error');
      return;
    }

    setRecordState('init');

    joinRoomAsRecorder({ room: id, token })
      .then((res) => {
        setMediaConfig({
          roomId: res.meet.meetId,
          token: res.mediaMeta.token,
        });

        startRecord(fileName);
        setRecordState('process');
      })
      .catch(() => {
        setRecordState('error');
      });
  }, [id, token, fileName]);

  useEffect(() => {
    if (!token || recordState !== 'process') {
      return;
    }

    let isPoll = true;
    let timeout: NodeJS.Timeout | null = null;
    const poll = async () => {
      try {
        const { isRecording } = await checkIsRecording({ room: id, token });

        if (!isPoll || !isRecording) {
          handleStop();

          return;
        }
      } catch (err) {
        isPoll = false;
        stopRecord();
        setRecordState('error');
        return;
      }

      timeout = setTimeout(() => {
        poll();
      }, POLL_INTERVAL);
    };

    poll();

    return () => {
      isPoll = false;

      if (timeout) {
        clearTimeout(timeout);

        timeout = null;
      }
    };
  }, [id, token, handleStop, recordState]);

  const { tracks } = useJitsi(mediaConfig);

  return (
    <>
      <button className="activate-sound" onClick={resume} />
      <button className="record-state record-stop" onClick={handleStop} />
      {recordState === 'init' ? (
        <div className="record-state record-starting">record starting</div>
      ) : recordState === 'process' ? (
        <div className="record-state record-process">record process</div>
      ) : recordState === 'ready' ? (
        <div className="record-state record-ready">record ready</div>
      ) : recordState === 'error' ? (
        <div className="record-state record-error">record error</div>
      ) : (
        <div className="record-state record-unknown">unknown record state</div>
      )}
      {Object.entries(tracks).map(
        ([key, track]) => track.audio && <TrackNode key={key} track={track.audio} />,
      )}
    </>
  );
};

export default Recorder;
