import { useState, useCallback } from 'react';
import cn from 'classnames';

import Input from 'ui/Input';
import Button from 'ui/Button';
import { ReactComponent as SendIcon } from 'assets/SendIcon.svg';
import { targetValue, prevent } from 'utils/events';

import styles from './Chat.module.scss';

interface ChatInputProps {
  className?: string;
  onMessage: (e: { text: string }) => any;
  placeholder?: string;
  buttonTitle?: string;
}

const ChatInput: React.FC<ChatInputProps> = ({
  className,
  onMessage,
  placeholder,
  buttonTitle,
}) => {
  const [input, setInput] = useState('');

  const handleMessage = useCallback(() => {
    const text = input.trim();

    if (!text) {
      return;
    }

    onMessage({ text });

    setInput('');
  }, [onMessage, input]);

  return (
    <form className={cn(styles.inputWrapper, className)}>
      <Input
        className={styles.inputWrapperInput}
        autoFocus
        autoComplete="off"
        placeholder={placeholder}
        scale="sm"
        color="secondary"
        value={input}
        onInput={targetValue(setInput)}
      />
      <Button
        className={styles.inputWrapperButton}
        variant="thin"
        title={buttonTitle}
        isRounded
        isBordered
        size="xsm"
        color="simple"
        onClick={prevent(handleMessage)}
      >
        <SendIcon />
      </Button>
    </form>
  );
};

export default ChatInput;
