import { useCallback } from 'react';
import cn from 'classnames';

import Dropdown from '../Dropdown';

import styles from './Select.module.scss';

interface SelectProps<T> extends React.ComponentProps<typeof Dropdown> {
  value?: T;
  list: T[];
  onChange?: (e: T) => void;
  getItem: (item: T, index: number) => React.ReactNode;
  getId?: (item: T) => number | string;
  isNowrap?: boolean;
  isCloseOnSelect?: boolean;
}

const Select: <T>(p: SelectProps<T>) => React.ReactElement<SelectProps<T>> = ({
  value,
  onChange,
  isNowrap = false,
  getId = (item) => (item as any)._id || (item as any).id,
  getItem,
  list,
  isCloseOnSelect = true,
  ...props
}) => {
  const valueKey = value ? getId(value) : null;

  const handleItemSelect = useCallback(
    (item: typeof list[0]) => {
      onChange && onChange(item);
    },
    [onChange],
  );

  return (
    <Dropdown
      renderDropdown={({ onOpen }) => (
        <ul className={cn(styles.select, isNowrap && styles.noWrap)}>
          {list.map((item, index) => {
            const itemKey = getId(item);
            const isSelected = valueKey === itemKey;

            return (
              <li
                key={itemKey}
                className={cn(styles.selectItem, isSelected && styles.selectItemActive)}
                onClick={() => {
                  if (isSelected) {
                    return;
                  }

                  isCloseOnSelect && onOpen(false);
                  handleItemSelect(item);
                }}
              >
                <div className={styles.selectItemText}>{getItem(item, index)}</div>
              </li>
            );
          })}
        </ul>
      )}
      {...props}
    />
  );
};

export default Select;
