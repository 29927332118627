import { createContext, useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';

export const SocketContext = createContext<Socket | null>(null);

interface QueryParams {
  [key: string]: string;
}

interface ProviderProps {
  url: string;
  query?: QueryParams;
  token?: string;
}

function useSocket(url: string, query?: QueryParams, token?: string) {
  const [connection, setConnection] = useState<Socket | null>(null);

  useEffect(() => {
    const parsedUrl = new URL(url);

    const socket = io(parsedUrl.origin, {
      path: parsedUrl.pathname,
      query,
      auth: {
        token,
      },
      transports: ['websocket'],
    });

    setConnection(socket);

    return () => {
      socket.disconnect();

      setConnection(null);
    };
  }, [url, query, token]);

  return connection;
}

const Provider: React.FC<ProviderProps> = ({ url, query, token, children }) => {
  const socket = useSocket(url, query, token);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export default Provider;
