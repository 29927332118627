import { useCallback, useEffect } from 'react';

import { MediaTrack } from '../jitsi/types';
import { on, off, once } from 'utils/dom';
import { download } from 'utils/files';

const AudioContext = window.AudioContext || (window as any).webkitAudioContext;

const audioContext = new AudioContext();

export default function useAudioContext() {
  const resume = useCallback(() => audioContext.resume(), []);

  return {
    audioContext,
    resume,
  };
}

type PlayHandlers = {
  globalClick: undefined | ((...args: any) => any);
  canPlay: (...args: any) => any;
};

export function setupPlayHandlers(el: HTMLMediaElement) {
  const canPlay = () => {
    el.play().catch((err) => {
      console.log('Error on autoPlay user track', err);
    });
  };

  const handlers: PlayHandlers = { globalClick: undefined, canPlay };

  el.play().catch((err) => {
    console.log('Error on autoPlay user track', err);

    handlers.globalClick = once(document, 'click', () => {
      handlers.globalClick = undefined;

      el?.play().catch((err) => {
        console.log('Error on try autoplay on user gesture', err);
      });
    });
  });

  on(el, 'canplaythrough', canPlay);

  return handlers;
}

export function dropPlayHandlers(el: HTMLMediaElement, handlers: PlayHandlers) {
  if (handlers.globalClick) {
    off(document, 'click', handlers.globalClick);
  }

  off(el, 'canplaythrough', handlers.canPlay);
}

export function useRenderTrack(audioRef: React.RefObject<HTMLMediaElement>, track: MediaTrack) {
  const { audioContext } = useAudioContext();

  useEffect(() => {
    const el = audioRef.current;

    if (!el || !track) {
      return;
    }

    if ((audioContext.state as string) === 'interrupted') {
      audioContext.resume().catch((err) => {
        console.log('Error on audioContext resuming', err);
      });
    }

    console.log('render audio track', track.type, 'audio context state', audioContext.state);

    track.attach(el);

    const mediaTrack = audioContext.createMediaElementSource(el);

    mediaTrack.connect(audioContext.destination);

    const handlers = setupPlayHandlers(el);

    return () => {
      dropPlayHandlers(el, handlers);

      track.detach(el);

      mediaTrack.disconnect();
    };
  }, [audioRef, audioContext, track]);
}

const dest = audioContext.createMediaStreamDestination();

export function useRecordTrack(track: MediaTrack) {
  const { audioContext } = useAudioContext();

  useEffect(() => {
    if (!track) {
      return;
    }

    if ((audioContext.state as string) === 'interrupted') {
      audioContext.resume().catch((err) => {
        console.log('Error on audioContext resuming', err);
      });
    }

    const audioEl = new Audio();

    audioEl.srcObject = track.stream;

    const source = audioContext.createMediaStreamSource(track.stream);

    source.connect(audioContext.destination);
    source.connect(dest);

    return () => {
      source.disconnect(audioContext.destination);
      source.disconnect(dest);
      audioEl.srcObject = null;
    };
  }, [track, audioContext]);
}

let recorder: MediaRecorder | null;
let recordedChunks: Blob[] = [];

const mimeType = 'audio/webm;codecs=opus';

export function startRecord(fileName?: string) {
  recorder = new MediaRecorder(dest.stream);

  recorder.onerror = (err) => {
    console.error('MediaRecorder handle error', err);
  };

  recorder.ondataavailable = (ev) => {
    recordedChunks.push(ev.data);
  };

  recorder.onstop = (ev) => {
    const blob = new Blob(recordedChunks, {
      type: mimeType,
    });
    recordedChunks = [];

    download(fileName ?? `record-${new Date().toISOString()}.webm`, blob);
  };

  recorder.start();

  console.log('record started');
}

export function stopRecord() {
  if (!recorder) {
    throw new TypeError('Recorder is not created!!');
  }

  recorder.stop();

  recorder = null;

  console.log('record stopped');
}
