import omit from 'lodash/omit';

import { UserTracksCollection, UserTracks, MediaTrack, MediaTrackType } from './types';

export const initState: {
  userIdToJid: { [key: string]: string };
  tracks: UserTracksCollection;
} = {
  userIdToJid: {},
  tracks: {
    currentUser: {},
  },
};

type Action =
  | {
      type: 'onLocalTracks';
      payload: {
        tracks: UserTracks;
      };
    }
  | {
      type: 'onAddLocalTrack';
      payload: {
        track: MediaTrack;
      };
    }
  | {
      type: 'onRemoveLocalTrack';
      payload: {
        trackType: MediaTrackType;
      };
    }
  | {
      type: 'onUser';
      payload: {
        jitId: string;
        userId: string;
      };
    }
  | {
      type: 'removeUser';
      payload: {
        jitId: string;
      };
    }
  | {
      type: 'onTracks';
      payload: {
        jitId: string;
        tracks: UserTracks;
      };
    }
  | {
      type: 'onAddTrack';
      payload: {
        jitId: string;
        track: MediaTrack;
      };
    }
  | {
      type: 'onRemoveTrack';
      payload: {
        jitId: string;
        trackType: MediaTrackType;
      };
    };

export function reducer(state: typeof initState, action: Action) {
  switch (action.type) {
    case 'onLocalTracks':
    case 'onTracks': {
      const { tracks } = action.payload;
      const userId =
        action.type === 'onTracks' ? state.userIdToJid[action.payload.jitId] : 'currentUser';

      if (!userId) {
        return state;
      }

      return {
        ...state,
        tracks: {
          ...state.tracks,
          [userId]: tracks,
        },
      };
    }

    case 'onAddLocalTrack':
    case 'onAddTrack': {
      const { track } = action.payload;
      const userId =
        action.type === 'onAddTrack' ? state.userIdToJid[action.payload.jitId] : 'currentUser';

      if (!userId) {
        return state;
      }

      return {
        ...state,
        tracks: {
          ...state.tracks,
          [userId]: {
            ...state.tracks[userId],
            [track.type]: track,
          },
        },
      };
    }

    case 'onRemoveLocalTrack':
    case 'onRemoveTrack': {
      const { trackType } = action.payload;
      const userId =
        action.type === 'onRemoveTrack' ? state.userIdToJid[action.payload.jitId] : 'currentUser';

      if (!userId) {
        return state;
      }

      return {
        ...state,
        tracks: {
          ...state.tracks,
          [userId]: omit(state.tracks[userId], trackType) as UserTracks,
        },
      };
    }

    case 'onUser': {
      const { userId, jitId } = action.payload;
      return {
        ...state,
        tracks: {
          ...state.tracks,
          [userId]: {},
        },
        userIdToJid: {
          ...state.userIdToJid,
          [jitId]: userId,
        },
      };
    }

    case 'removeUser': {
      const { jitId } = action.payload;
      const userId = state.userIdToJid[jitId];

      return {
        ...state,
        tracks: omit(state.tracks, userId) as UserTracksCollection,
        userIdToJid: omit(state.userIdToJid, jitId),
      };
    }

    default:
      return state;
  }
}
