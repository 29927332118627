import { useRef } from 'react';

import { MediaTrack } from 'hooks/jitsi/types';
import { useRenderTrack } from 'hooks/audio';

interface UserAudioProps {
  track: MediaTrack;
}

const UserAudio: React.FC<UserAudioProps> = ({ track }) => {
  const audioRef = useRef<null | HTMLAudioElement>(null);

  useRenderTrack(audioRef, track);

  return <audio ref={audioRef} autoPlay webkit-playsinline="true" playsInline controls={false} />;
};

export default UserAudio;
