import qs from 'qs';

import store from 'store';
import { getToken } from 'store/selectors';

const { REACT_APP_API_ENDPOINT } = process.env;

/**
 * Utils
 */

interface Options {
  token?: string;
}

function optionsFactory({ token }: Options = {}): RequestInit {
  const opts: RequestInit = {
    cache: 'no-cache',
    mode: 'cors',
    headers: {
      Authorization: token as string,
      'Content-Type': 'application/json',
    },
  };

  return opts;
}

async function parseResponse(res: Response) {
  const contentType = res.headers.get('content-type');

  try {
    if (contentType?.includes('application/json')) {
      return await res.json();
    } else {
      return await res.text();
    }
  } catch (e) {
    return null;
  }
}

/**
 * Decorators for handle creation, response flow
 */

type RequestCb<T> = (url: string, params?: any, options?: Options) => Promise<T>;

function failableRequest(cb: RequestCb<Response>) {
  return async (...args: Parameters<typeof cb>) => {
    const res = await cb(...args);

    if (res.ok) {
      return res;
    } else {
      throw res;
    }
  };
}

interface HttpError extends Response {
  payload?: any;
}

function parseableRequest(cb: RequestCb<Response>) {
  return async (...args: Parameters<typeof cb>) => {
    try {
      const res = await cb(...args);

      return parseResponse(res);
    } catch (e) {
      if (e instanceof Response) {
        const payload = await parseResponse(e);

        (e as HttpError).payload = payload;

        throw e;
      }

      throw e;
    }
  };
}

function authableRequest<T extends any>(cb: RequestCb<T>) {
  return async (url: string, params?: any, options?: Options) => {
    try {
      return await cb(url, params, {
        token: getToken(store.getState()) ?? undefined,
        ...(options ?? {}),
      });
    } catch (err) {
      if (err?.status === 401) {
        store.dispatch({ type: 'logout' });
      }

      throw err;
    }
  };
}

/**
 * Public api
 */

export const getRequest = authableRequest(
  parseableRequest(
    failableRequest((url: string, params?: any, options?: Options) => {
      const add = params ? `?${qs.stringify(params)}` : '';

      return fetch(`${REACT_APP_API_ENDPOINT}/${url}${add}`, optionsFactory(options));
    }),
  ),
);

export const postRequest = authableRequest(
  parseableRequest(
    failableRequest((url: string, params?: any, options?: Options) =>
      fetch(`${REACT_APP_API_ENDPOINT}/${url}`, {
        ...optionsFactory(options),
        method: 'POST',
        body: params && JSON.stringify(params),
      }),
    ),
  ),
);

export const putRequest = authableRequest(
  parseableRequest(
    failableRequest((url: string, params?: any, options?: Options) =>
      fetch(`${REACT_APP_API_ENDPOINT}/${url}`, {
        ...optionsFactory(options),
        method: 'PUT',
        body: params && JSON.stringify(params),
      }),
    ),
  ),
);

export const deleteRequest = authableRequest(
  parseableRequest(
    failableRequest((url: string, params?: any, options?: Options) =>
      fetch(`${REACT_APP_API_ENDPOINT}/${url}`, {
        ...optionsFactory(options),
        method: 'DELETE',
        body: params && JSON.stringify(params),
      }),
    ),
  ),
);

export function fakeResponse<T>(resp: T): Promise<T> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(resp);
    }, 600);
  });
}
