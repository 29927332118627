import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'ui/Button';
import Tooltip from 'ui/Tooltip';
import { copy } from '../utils/clipboard';

interface CopyBtnProps {
  content: string;
}

const CopyBtn: React.FC<CopyBtnProps> = ({ content, children }) => {
  const { t } = useTranslation('conference');
  const [isHovered, setHover] = useState(false);
  const [isCopied, setClicked] = useState(false);

  useEffect(() => {
    if (!isCopied) {
      return;
    }

    const timer = setTimeout(() => {
      setClicked(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [isCopied]);

  const onClick = useCallback(() => {
    setClicked(true);
    copy(content);
  }, [content]);

  return (
    <Tooltip
      content={isCopied ? t('tooltip.copied') : t('tooltip.hovered')}
      isVisible={isCopied || isHovered}
    >
      <Button
        size="sm"
        color="dark"
        onClick={onClick}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default CopyBtn;
