import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import ru from './locales/ru.json';

// TODO: maybe better use browser language detector. 95% chance of correct user lang
export function detectLang() {
  return navigator.language.slice(0, 2).toLowerCase();
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    cleanCode: true,
    fallbackLng: 'en',
    lng: detectLang(),
    ns: 'ui',
    defaultNS: 'ui',

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      en,
      ru,
    },

    saveMissing: true,
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.lang = lng;
});

export default i18n;
