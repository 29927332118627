import cn from 'classnames';

import { useVAD } from 'hooks/jitsi';
import { MediaTrack } from 'hooks/jitsi/types';

import styles from './SoundVolume.module.scss';

import { ReactComponent as MicOffIcon } from 'assets/MicOffIcon.svg';

interface SoundVolumeProps {
  isAnimate?: boolean;
  level?: number;
}

const SoundVolume: React.FC<SoundVolumeProps> = ({ isAnimate = false, level = 0 }) => {
  return (
    <div className={cn(styles.soundVolume, isAnimate && styles.soundVolumeAnimate)}>
      <div style={{ height: `${Math.min(level, 100)}%` }} />
      <div style={{ height: `${Math.min(level * 3, 100)}%` }} />
      <div style={{ height: `${Math.min((level / 2) * 3, 100)}%` }} />
    </div>
  );
};

export default SoundVolume;

interface TrackVolumeProps {
  track?: MediaTrack;
  className?: string;
}

export const TrackVolume: React.FC<TrackVolumeProps> = ({ track, className }) => {
  const volume = useVAD(track);

  return volume === null ? <MicOffIcon className={className} /> : <SoundVolume level={volume} />;
};
