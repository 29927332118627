import { userJoin as joinRequest, userProfile, userJoinViaDirect as joinDirect } from '../api';

import { AppThunk } from './';

type RequestAction<T extends (...args: any) => any> = (
  ...args: Parameters<T>
) => AppThunk<ReturnType<T>>;

export const userJoin: RequestAction<typeof joinRequest> = (payload) => async (dispatch) => {
  const res = await joinRequest(payload);

  dispatch({
    type: 'initSession',
    currentUser: res.user,
    token: res.token,
  });

  return res;
};

export const userJoinViaService =
  ({ token }: { token: string }): AppThunk<ReturnType<typeof userProfile>> =>
  async (dispatch) => {
    dispatch({
      type: 'updateToken',
      token,
    });

    const res = await userProfile();

    dispatch({
      type: 'initSession',
      currentUser: res.user,
      token,
    });

    return res;
  };

export const userJoinViaDirect =
  (params: Parameters<typeof joinDirect>[0]): AppThunk<ReturnType<typeof userProfile>> =>
  async (dispatch) => {
    const res = await joinDirect(params);

    dispatch({
      type: 'initSession',
      currentUser: res.user,
      token: res.token,
    });

    return res;
  };
