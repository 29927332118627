import notImplemented from 'utils/notImplemented';
import { ID, Slide, CurrentPresentation, Meeting } from 'store/models';

import GlobalizebleObject from './GlobalizebleObject';

export const defaultAppFn = () => {
  throw new Error('Method not described!!');
};

class App extends GlobalizebleObject {
  _app: {
    getCurrentState: () => {
      meet: Meeting;
      currentSlides: Slide[];
      currentPresentation?: CurrentPresentation;
    };
    setSlide: (id: ID) => void;
    setPresent: (id: ID) => void;
    goToSlide: (index: number) => void;
    goNextSlide: () => void;
    goBackSlide: () => void;
    getDevicesState: () => {
      isRecord: boolean;
      isVideo: boolean;
      isAudio: boolean;
      isScreenShare: boolean;
    };
    recordToggle: () => any;
    videoToggle: () => any;
    audioToggle: () => any;
    shareToggle: () => any;
  } = {
    getCurrentState: defaultAppFn,
    setSlide: defaultAppFn,
    setPresent: defaultAppFn,
    goToSlide: defaultAppFn,
    goNextSlide: defaultAppFn,
    goBackSlide: defaultAppFn,
    getDevicesState: defaultAppFn,
    videoToggle: defaultAppFn,
    audioToggle: defaultAppFn,
    recordToggle: defaultAppFn,
    shareToggle: defaultAppFn,
  };

  constructor(app?: Partial<App['_app']>) {
    super();

    if (app) {
      this._app = {
        ...this._app,
        ...app,
      };
    }

    (['newRecord', 'startRecordingAudio', 'stopRecordingAudio'] as const).forEach((key) => {
      this[key] = notImplemented(key);
    });
  }

  _descibeMethods(methods: Partial<App['_app']>) {
    this._app = {
      ...this._app,
      ...methods,
    };
  }

  _undescibeMethods(methods: Array<keyof App['_app']>) {
    methods.forEach((name) => {
      this._app[name] = defaultAppFn;
    });
  }

  async turnOnRecord() {
    if (this._app.getDevicesState().isRecord) {
      return;
    }

    return this._app.recordToggle();
  }

  async turnOffRecord() {
    if (!this._app.getDevicesState().isRecord) {
      return;
    }

    return this._app.recordToggle();
  }

  async turnOnVideo() {
    if (this._app.getDevicesState().isVideo) {
      return;
    }

    return this._app.videoToggle();
  }

  async turnOffVideo() {
    if (!this._app.getDevicesState().isVideo) {
      return;
    }

    return this._app.videoToggle();
  }

  async turnOnAudio() {
    if (!this._app.getDevicesState().isAudio) {
      return;
    }

    return this._app.audioToggle();
  }

  async turnOffAudio() {
    if (!this._app.getDevicesState().isAudio) {
      return;
    }

    return this._app.audioToggle();
  }

  goToSlide(slideOrder: number | string) {
    const { currentSlides, currentPresentation } = this._app.getCurrentState();
    let nextSlide;

    const findSlide = (slide: Slide) => `${slide.order}` === `${slideOrder}`;

    // Lookup at current branch
    nextSlide = currentSlides.find(findSlide);

    if (!nextSlide) {
      // Lookup at whole presentation. Change branch case.
      nextSlide = currentPresentation?.slides.find(findSlide);
    }

    if (!nextSlide) {
      console.warn('AppSdk@goToSlide: slide not found', slideOrder, currentPresentation?.slides);

      return;
    }

    this._app.setSlide(nextSlide._id);
  }

  goToFirst() {
    this._app.goToSlide(1);
  }

  goToLast() {
    const { currentSlides } = this._app.getCurrentState();

    this._app.goToSlide(currentSlides.length);
  }

  goToNext() {
    this._app.goNextSlide();
  }

  goToPrevious() {
    this._app.goBackSlide();
  }

  openLink(url: string) {
    if (url.startsWith('#slide')) {
      const order = url.replace('#slide', '');

      return this.goToSlide(order);
    }

    if (url.startsWith('#app:')) {
      const [presentAlias, scenarioName] = url.replace('#app:', '').split(':');

      const { meet } = this._app.getCurrentState();

      const present = meet.presentations.find((pres) => pres.alias === presentAlias);

      if (!present) {
        console.warn('AppSdk@openLink: presentation not found', meet.presentations, presentAlias);

        return;
      }

      let nextPresentId = present._id;

      if (scenarioName) {
        const scenario = present.scenarios.find((scen) => scen.name === scenarioName);

        if (!scenario) {
          console.warn('AppSdk@openLink: scenario not found', present.scenarios, scenarioName);

          return;
        }

        nextPresentId = scenario._id;
      }

      this._app.setPresent(nextPresentId);
    }
  }

  // Not implemented
  newRecord() {}
  startRecordingAudio() {}
  stopRecordingAudio() {}
}

export default App;
