import { CurrentUser, ID } from './models';

export interface State {
  currentUser: CurrentUser | null;
  slideVotes: {
    [key: string]: 'like' | 'dislike' | undefined;
  };
  token: string | null;
  flags: {
    isSliderPreviewOpen: boolean;
    isConferenceOpen: boolean;
    isSelfPointer: boolean;
    isConferenceFullScreen: boolean;
  };
}

export type Action =
  | {
      type: 'initSession';
      currentUser: CurrentUser;
      token: string;
    }
  | {
      type: 'updateToken';
      token: string;
    }
  | {
      type: 'setFlag';
      name: keyof State['flags'];
      value: boolean;
    }
  | {
      type: 'logout';
    }
  | {
      type: 'slideVote';
      slideId: ID;
      cacheKey: string;
      voteTo?: 'like' | 'dislike';
    };

const defaultState: State = {
  currentUser: null,
  slideVotes: {},
  token: null,
  flags: {
    isSliderPreviewOpen: true,
    isConferenceOpen: true,
    isSelfPointer: true,
    isConferenceFullScreen: false,
  },
};

export default function reducer(state: State = defaultState, action: Action): State {
  const { type, ...payload } = action;

  switch (action.type) {
    case 'initSession':
    case 'updateToken': {
      return {
        ...state,
        ...payload,
      };
    }

    case 'setFlag': {
      return {
        ...state,
        flags: {
          ...state.flags,
          [action.name]: action.value,
        },
      };
    }

    case 'logout': {
      return {
        ...state,
        currentUser: null,
        token: null,
      };
    }

    case 'slideVote': {
      return {
        ...state,
        slideVotes: {
          ...state.slideVotes,
          [`${action.cacheKey}-${action.slideId}`]: action.voteTo,
        },
      };
    }

    default:
      return state;
  }
}
