import { Message, User, MeetingConfig } from 'store/models';

import { defaultAppFn } from './App';

interface WebViewBinding {
  registerHandler: (name: string, cb: (data: any, cb?: (payload: object) => void) => void) => void;
  callHandler: (name: string, data: object, cb: (response: any) => void) => void;
}

interface RoomInfo {
  roomUI: boolean;
  status: 'pending' | 'ended' | 'in_progress';
}

const defaultMethods = {
  exitRoom: defaultAppFn,
  sendMessage: defaultAppFn,
  getRoomInfo: () =>
    ({
      status: 'pending',
      roomUI: false,
    } as RoomInfo),
};

class WebView {
  wvBinding?: WebViewBinding;
  _app: {
    exitRoom: () => void;
    sendMessage: (payload: { text: string }) => void;
    getRoomInfo: () => RoomInfo;
  } = { ...defaultMethods };

  constructor(app?: Partial<WebView['_app']>) {
    if (app) {
      this._app = {
        ...this._app,
        ...app,
      };
    }
  }

  _descibeMethods(methods: Partial<WebView['_app']>) {
    this._app = {
      ...this._app,
      ...methods,
    };
  }

  _undescibeMethods(methods: Array<keyof WebView['_app']>) {
    methods.forEach((name) => {
      this._app[name] = defaultMethods[name];
    });
  }

  setVwBinding(binding: WebViewBinding) {
    this.wvBinding = binding;

    // Init test methods
    binding.callHandler('testiOSCallback', { foo: 'bar' }, (res: any) => {
      console.log('JS got response', res);
    });

    binding.registerHandler('testJavascriptHandler', (data: any, cb) => {
      console.log('iOS called testJavascriptHandler with', data);

      cb && cb({ 'Javascript Says': 'Right back atcha!' });
    });

    this._registerCallbacks(binding);
  }

  _registerCallbacks(binding: WebViewBinding) {
    binding.registerHandler('exitMeeting', (_data, cb) => {
      this._app.exitRoom();

      cb && cb({ status: 'disconnected' });
    });

    binding.registerHandler('sendMessage', (text: string, cb) => {
      this._app.sendMessage({ text });

      cb && cb({ status: 'sent' });
    });

    binding.registerHandler('getRoomInfo', (_data, cb) => {
      cb && cb(this._app.getRoomInfo());
    });
  }

  gotMessage(message: Message) {
    this.wvBinding?.callHandler(
      'gotMessage',
      {
        message: message.text,
        user: message.author.name,
        dateString: message.createdAt,
      },
      (response) => {
        console.log('WebView@gotMessage res:', response);
      },
    );
  }

  refreshUsersCount(users: User[]) {
    this.wvBinding?.callHandler(
      'refreshUsersCount',
      {
        names: users.map((user) => user.name),
      },
      (response) => {
        console.log('WebView@refreshUsersCount res:', response);
      },
    );
  }

  settings(
    { meetId, mediaToken, username }: { meetId: string; mediaToken: string; username: string },
    config: MeetingConfig,
  ) {
    this.wvBinding?.callHandler(
      'settings',
      {
        meetId,
        mediaToken,
        username,
        isChatEnabled: config.isChat,
        isVideoEnabled: config.isVideo,
      },
      (res) => {
        console.log('WebView@settings res:', res);
      },
    );
  }

  socketDisconnect() {
    this.wvBinding?.callHandler('socketDisconnect', {}, (response) => {
      console.log('WebView@socketDisconnect res:', response);
    });
  }

  setRoomUi(isView: boolean) {
    this.wvBinding?.callHandler('setRoomUi', { isView }, (response) => {
      console.log('WebView@setRoomUi res:', response);
    });
  }

  static lookupBridge(obj: any, cb: WebView['setVwBinding']) {
    try {
      if (obj.WKWebViewJavascriptBridge) {
        return cb(obj.WKWebViewJavascriptBridge);
      }

      if (obj.WKWVJBCallbacks) {
        return obj.WKWVJBCallbacks.push(cb);
      }

      obj.WKWVJBCallbacks = [cb];
      obj.webkit?.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
    } catch (err) {
      console.warn('WebView: bridge not istalled', err);
    }
  }
}

export default WebView;
