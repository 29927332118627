import { Terms as TermsModel } from 'store/models';
import { useTranslation } from 'react-i18next';

import ScrollContainer from 'ui/ScrollContainer';

interface TermsProps {
  terms: TermsModel;
  className?: string;
}

const Terms: React.FC<TermsProps> = ({ terms, className }) => {
  const { i18n } = useTranslation();

  return (
    <ScrollContainer className={className}>
      {terms.terms[i18n.language] ?? terms.terms.en}
    </ScrollContainer>
  );
};

export default Terms;
