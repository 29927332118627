import useFrameAspect from 'hooks/useFrameAspect';
import { MediaTrack } from 'hooks/jitsi/types';
import UserVideo from '../UserVideo';

import styles from './Canvas.module.scss';

interface VideoCanvasProps {
  video: MediaTrack;
  zoom?: number;
}

const VideoCanvas: React.FC<VideoCanvasProps> = ({ video, zoom = 1 }) => {
  const { wrapperRef, frameRef, frameWrapperStyles } = useFrameAspect({ zoom });

  return (
    <div className={styles.canvas} ref={wrapperRef as any}>
      <div ref={frameRef} className={styles.frameWrapper} style={frameWrapperStyles}>
        <UserVideo className={styles.video} track={video} />
      </div>
    </div>
  );
};

export default VideoCanvas;
