import { useCallback, useLayoutEffect, useRef, useState, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

import { rectToRect, Dimentions } from 'utils/dimentions';

interface RectDimentions extends Dimentions {
  top: number;
  left: number;
  clientWidth: number;
  clientHeight: number;
  parentOffsetTop: number;
  parentOffsetLeft: number;
}

export default function useFrameAspect({ zoom }: { zoom: number }, deps: any[] = []) {
  const { width = 0, height = 0, ref: wrapperRef } = useResizeDetector();
  const [frameAspect, setFrameAspect] = useState(1);
  const frameRef = useRef<HTMLDivElement>(null);
  const [frameRect, setFrameRect] = useState<RectDimentions>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    clientWidth: 0,
    clientHeight: 0,
    parentOffsetTop: 0,
    parentOffsetLeft: 0,
  });

  const updateAspect = useCallback(() => {
    const frameEl = frameRef.current;

    if (!frameEl) {
      return;
    }

    const aspect = rectToRect(
      {
        width,
        height,
      },
      {
        height: frameEl.clientHeight,
        width: frameEl.clientWidth,
      },
    );

    setFrameAspect(aspect * zoom);
  }, [width, height, zoom]);

  useLayoutEffect(() => {
    updateAspect();

    const frameEl = frameRef.current;

    if (!frameEl) {
      return;
    }

    const raf = requestAnimationFrame(() => {
      const rect = frameEl.getBoundingClientRect();

      setFrameRect({
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
        clientHeight: frameEl.clientHeight,
        clientWidth: frameEl.clientWidth,
        parentOffsetTop: frameEl.parentElement?.offsetTop ?? 0,
        parentOffsetLeft: frameEl.parentElement?.offsetLeft ?? 0,
      });
    });

    return () => {
      cancelAnimationFrame(raf);
    };
    // spred of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAspect, ...deps]);

  const frameWrapperStyles = useMemo(
    () => ({
      transform: `translate3d(-50%, -50%, 0) scale(${frameAspect})`,
    }),
    [frameAspect],
  );

  return {
    frameWrapperStyles,
    frameAspect,
    frameRect,
    wrapperRef,
    frameRef,
  };
}
