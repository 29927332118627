import cn from 'classnames';

import { ReactComponent as TimeIcon } from 'assets/TimeIcon.svg';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  isCenter?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ isCenter = false }) => (
  <TimeIcon className={cn(styles.timer, isCenter && styles.isCenter)} />
);

export default Spinner;
