import cn from 'classnames';
import { useCallback } from 'react';

import { useLabelId } from '../hooks';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
  value?: boolean;
  className?: string;
  onChange?: (e: boolean) => void;
  type?: 'radio' | 'checkbox';
  disabled?: boolean;
}

const noop = () => {};

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  value,
  disabled = false,
  type = 'checkbox',
  onChange = noop,
  className,
}) => {
  const id = useLabelId('checkbox');
  const handleChange = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);

  return (
    <div className={cn(styles.wrapper, className)}>
      <input
        id={id}
        type={type}
        className={styles.input}
        checked={value}
        disabled={disabled}
        onChange={handleChange}
      />
      <label htmlFor={id} className={styles.label}>
        {children}
      </label>
    </div>
  );
};

export default Checkbox;
