import cn from 'classnames';

import { ReactComponent as LikeOnIcon } from 'assets/LikeOnIcon.svg';

import styles from './PresenterLayout.module.scss';

interface VotingProps {
  onVote?: (e: 'like' | 'dislike' | undefined) => any;
  voteTo?: 'like' | 'dislike';
}

const Voting: React.FC<VotingProps> = ({ voteTo, onVote }) => (
  <>
    <button
      className={cn(
        styles.headerActionElement,
        styles.headerActionButton,
        voteTo === 'dislike' && styles.headerActionButtonActive,
      )}
      onClick={() => onVote && onVote(voteTo === 'dislike' ? undefined : 'dislike')}
    >
      <LikeOnIcon className={styles.dislikeIcon} />
    </button>
    <button
      className={cn(
        styles.headerActionElement,
        styles.headerActionButton,
        voteTo === 'like' && styles.headerActionButtonActive,
      )}
      onClick={() => onVote && onVote(voteTo === 'like' ? undefined : 'like')}
    >
      <LikeOnIcon className={styles.likeIcon} />
    </button>
  </>
);

export default Voting;
