import { useEffect, useRef, useContext, createContext } from 'react';

import Ctm from './Ctm';
import App from './App';
import WebView from './WebView';

const globalAppMethods = [
  'turnOnVideo',
  'turnOffVideo',
  'turnOnAudio',
  'turnOffAudio',
  'goToPrevious',
  'goToNext',
  'goToSlide',
  'goToFirst',
  'goToLast',
  'openLink',
  'newRecord',
  'startRecordingAudio',
  'stopRecordingAudio',
];

export function usePublicCtmSdk({ getAppState, trackEvent }: ConstructorParameters<typeof Ctm>[0]) {
  useEffect(() => {
    const ctm = new Ctm({
      getAppState,
      trackEvent,
    });

    ctm._patchGlobal(window, 'ctm');

    return () => {
      ctm._clearGlobal(window, 'ctm');
    };
  }, [getAppState, trackEvent]);
}

export const SdkContext = createContext<App | undefined>(undefined);

export function usePublicAppSdk(methods?: ConstructorParameters<typeof App>[0]) {
  const sdk = useRef<App>();

  useEffect(
    () => {
      const app = new App(methods);

      const patchOpts = {
        methods: globalAppMethods,
        prefix: 'CTAPP',
      };

      app._patchGlobal(window, 'ctmApp', patchOpts);

      sdk.current = app;

      return () => {
        app._clearGlobal(window, 'ctmApp', patchOpts);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods ? Object.values(methods) : [],
  );

  return sdk;
}

export function useDescribePublicAppSdk(methods: Parameters<App['_descibeMethods']>[0]) {
  const sdk = useContext(SdkContext);

  useEffect(() => {
    sdk?._descibeMethods(methods);

    return () => {
      sdk?._undescibeMethods(Object.keys(methods) as (keyof typeof methods)[]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, ...Object.values(methods)]);
}

const wvSdk = new WebView();

export function useDescribeWebViewSdk(methods: Parameters<WebView['_descibeMethods']>[0]) {
  useEffect(() => {
    wvSdk._descibeMethods(methods);

    return () => {
      wvSdk._undescibeMethods(Object.keys(methods) as (keyof typeof methods)[]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(methods));
}

WebView.lookupBridge(window, wvSdk.setVwBinding.bind(wvSdk));

export { Ctm, App, WebView, wvSdk };
