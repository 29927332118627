import cn from 'classnames';

import styles from './ScrollContainer.module.scss';

interface ScrollContainerProps {
  className?: string;
}

const ScrollContainer: React.FC<ScrollContainerProps> = ({ className, children }) => {
  return <div className={cn(styles.container, className)}>{children}</div>;
};

export default ScrollContainer;
