import { Notification, ID } from './models';

export interface State {
  notifications: Notification[];
}

export type Action =
  | {
      type: 'addNotifcation';
      notificaiton: Partial<Notification>;
    }
  | {
      type: 'removeNotification';
      id: ID;
    };

const defaultState: State = {
  notifications: [],
};

function notifications(state: State = defaultState, action: Action): State {
  switch (action.type) {
    case 'removeNotification': {
      return {
        ...state,
        notifications: state.notifications.filter((not) => not.id !== action.id),
      };
    }

    case 'addNotifcation': {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: `notificaion-${Math.random()}-${Math.random()}`,
            title: 'default title text',
            ...action.notificaiton,
          },
        ],
      };
    }

    default:
      return state;
  }
}

export default notifications;
