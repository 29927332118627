import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';

import session, { Action as SessionAction } from './session';
import notifications, { Action as NotificationsAction } from './notifications';
import users, { Action as UsersAction } from './users';

type Action = SessionAction | NotificationsAction | UsersAction;

const middlewares = [thunk];
let composeEnhancers = compose;

if (process.env.NODE_ENV !== 'production') {
  if ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
}

const rootReducer = combineReducers({
  session,
  notifications,
  users,
});

const enhancers = composeEnhancers(applyMiddleware(...middlewares));
const persistConfig = {
  key: 'root',
  version: 0,
  storage,
  whitelist: ['session'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, enhancers);

export default store;
export const persistor = persistStore(store as any);

export type AppDispatch = ThunkDispatch<RootState, undefined, Action>;
export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
