import Router from 'Router';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ErrorBoundary from './ErrorBoundary';
import Notifications from './components/Notifications';
import { PortalTarget } from 'ui/hooks';

import store, { persistor } from 'store';

function App() {
  return (
    <ErrorBoundary>
      <StoreProvider store={store}>
        <PersistGate persistor={persistor}>
          <Router />

          <Notifications />
          <PortalTarget />
        </PersistGate>
      </StoreProvider>
    </ErrorBoundary>
  );
}

export default App;
