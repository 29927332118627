import { Children, useMemo } from 'react';

import { parseLinks } from 'utils/parseTextToArray';

interface TextProps {
  text: string;
}

const Text: React.FC<TextProps> = ({ text }) => {
  const formattedText = useMemo(
    () =>
      Children.toArray(
        parseLinks(text, (linkText) => (
          <a href={linkText} target="_blank" rel="noreferrer nofollow">
            {linkText}
          </a>
        )),
      ),
    [text],
  );
  return <>{formattedText}</>;
};

export default Text;
