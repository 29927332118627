export interface JitsiUser {
  getId: () => string;
  _identity: {
    user: {
      id: string;
      name: string;
      email: string;
    };
  };
}

export type MediaTrackType = 'video' | 'audio';

export type MediaTrack = {
  conference: JitsiRoom;
  isLocal: () => boolean;
  stream: MediaStream;
  track: MediaStreamTrack;
  deviceId?: string;
  getParticipantId: () => string;
  mute: () => Promise<void>;
  unmute: () => Promise<void>;
  isMuted: () => boolean;
  getType: () => MediaTrackType;
  type: MediaTrackType;
  videoType: 'desktop' | 'camera' | null;
  attach: (el: HTMLMediaElement) => void;
  detach: (el: HTMLMediaElement) => void;
  dispose: () => Promise<any>;
  disposed: boolean;
  getDeviceId: () => string;
  getId: () => string;
  setAudioOutput: (audioOutputDeviceId: string) => void;
  isEnded: () => boolean;
} & EvenEmitter;

export type UserTracks = {
  audio?: MediaTrack;
  video?: MediaTrack;
};

export interface UserTracksCollection {
  [key: string]: UserTracks;
}

type EvenEmitterHandler = (e: string, fn: (...args: any) => any) => void;

interface EvenEmitter {
  addEventListener: EvenEmitterHandler;
  removeEventListener: EvenEmitterHandler;
}

export interface JitsiRoom extends EvenEmitter {
  myUserId: () => string;
  isModerator: () => boolean;
  selectParticipant: (id: string) => void;
  setReceiverVideoConstraint: (resolution: number) => void;
  setSenderVideoConstraint: (resolution: number) => void;
  muteParticipant: (id: string, mediaType: MediaTrackType) => void;
  addTrack: (e: MediaTrack) => Promise<void>;
  removeTrack: (e: MediaTrack) => Promise<void>;
  replaceTrack: (e: MediaTrack, t: MediaTrack) => Promise<void>;
  getLocalTracks: () => MediaTrack[];
  join: (password?: string) => void;
  setDisplayName: (name: string) => void;
  leave: () => Promise<void>;
  on: EvenEmitterHandler;
  off: EvenEmitterHandler;
  participants: {
    [key: string]: JitsiUser;
  };
}

export interface JitsiConnection extends EvenEmitter {
  initJitsiConference: (id: string, opts: any) => JitsiRoom;
  connect: () => void;
  disconnect: () => void;
}

export type RequestDevice = (active: boolean, deviceId?: string) => Promise<any>;

export interface DUStats {
  upload: number;
  download: number;
}

export interface RemoteStats {
  avgAudioLevels?: {};
  bitrate: DUStats & {
    audio: DUStats;
    video: DUStats;
  };
  connectionQuality: number;
  jvbRTT: number;
  maxEnabledResolution?: number;
  packetLoss: DUStats & {
    total: 0;
  };
  serverRegion?: string;
}

interface CodecInfo {
  audio: string;
  video: string;
}

interface ResolutionInfo {
  audio: number;
  video: number;
}

interface TransportInfo {
  ip: string;
  localCandidateType: string;
  localip: string;
  networkType: string;
  p2p: boolean;
  remoteCandidateType: string;
  rtt: number;
  type: 'udp' | 'tcp';
}

export interface LocalStats extends RemoteStats {
  bandwidth: DUStats;
  bridgeCount: number;
  codec: {
    [key: string]: CodecInfo;
  };
  framerate: {
    [key: string]: number;
  };
  localAvgAudioLevels?: {};
  resolution: {
    [key: string]: ResolutionInfo;
  };
  transport: TransportInfo[];
}

export function isLocalStats(stats: LocalStats | RemoteStats): stats is LocalStats {
  return 'bandwidth' in stats;
}

export interface UserStatsCollection {
  [key: string]: RemoteStats | LocalStats;
}
