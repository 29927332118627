import { useCallback, useContext } from 'react';
import throttle from 'lodash/throttle';

import { Analitics } from 'store/models';
import Provider, { AnaliticsContext, SendFn } from './Provider';

export { Provider };

let events: Analitics[] = [];

const sendAnalitics = throttle(
  async (send: SendFn) => {
    const pendingEvents = [...events];
    events = [];

    try {
      const res = send({ events: pendingEvents });

      if (res?.then) {
        await res;
      }
    } catch (err) {
      events.unshift(...pendingEvents);
    }
  },
  100,
  { leading: false },
);

export function useAnalytic() {
  const broadcastAnalytic = useContext(AnaliticsContext);

  return useCallback(
    (ev: Analitics) => {
      events.push(ev);

      sendAnalitics(broadcastAnalytic);
    },
    [broadcastAnalytic],
  );
}
