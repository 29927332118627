import { createContext, useCallback } from 'react';
import { sendAnalytic } from 'api';

import { Analitics } from 'store/models';
import { useBroadcast } from '../socket';

const defaultSendFn = () => {
  console.warn('Send function not defined!');
};

export type SendFn = (e: { events: Analitics[] }) => any;

export const AnaliticsContext = createContext<SendFn>(defaultSendFn);

interface ProviderProps {
  useSocket?: boolean;
  payload?: Omit<Parameters<typeof sendAnalytic>[0], keyof Parameters<SendFn>[0]>;
}

const Provider: React.FC<ProviderProps> = ({ children, payload = null, useSocket = false }) => {
  const socketSender = useBroadcast<{ events: Analitics[] }>('meet.analitycs');
  const httpSender = useCallback<SendFn>(
    (params) => {
      if (!payload) {
        throw new Error('Analitycs Provider: payload not passed!');
      }

      return sendAnalytic({ ...payload, ...params });
    },
    [payload],
  );

  return (
    <AnaliticsContext.Provider value={useSocket ? socketSender : httpSender}>
      {children}
    </AnaliticsContext.Provider>
  );
};

export default Provider;
