import { useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ID, Message as MessageType } from 'store/models';
import useDate from 'hooks/useDate';
import useBottomScroll from 'hooks/useBottomScroll';
import ChatInput from './ChatInput';

import styles from './Chat.module.scss';

import Text from './Text';

interface MessageProps {
  className?: string;
  message: MessageType;
}

const Message: React.FC<MessageProps> = ({ className, message }) => {
  const date = useDate(message.createdAt);

  return (
    <div className={cn(styles.message, className)}>
      <div className={styles.messageMeta}>
        <div className={styles.messageAuthor}>{message.author.name}</div>
        <div className={styles.messageDate}>{date}</div>
      </div>
      <div className={styles.messageText}>
        <Text text={message.text} />
      </div>
    </div>
  );
};

interface ChatProps {
  messages: MessageType[];
  onMessage: (e: { text: string }) => any;
  lastReadMessage: ID | null;
}

const Chat: React.FC<ChatProps> = ({ messages, onMessage, lastReadMessage }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('conference');

  useBottomScroll(scrollRef, messages);

  const messagesElem = messages.map((message, index) => {
    const isLastReadMessage = lastReadMessage === message._id && messages.length - 1 !== index;
    return (
      <Fragment key={message._id}>
        <Message className={styles.chatMessage} message={message} />
        {isLastReadMessage && (
          <div className={styles.messageUnread}>
            <div className={styles.messageUnreadTitle}>{t('chat.newMessage')}</div>
          </div>
        )}
      </Fragment>
    );
  });

  return (
    <div className={styles.chatWrapper}>
      <div ref={scrollRef} className={styles.chatMessages}>
        {messagesElem}
      </div>
      <ChatInput
        onMessage={onMessage}
        placeholder={t('chat.input.placeholder')}
        buttonTitle={t('chat.sendButton')}
      />
    </div>
  );
};

export default Chat;
