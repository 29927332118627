import { useRef, useEffect } from 'react';

import Canvas from './Canvas';
import VideoCanvas from './VideoCanvas';
import SlidesPreview from './SlidesPreview';
import { ID, Meeting, isPresentation } from 'store/models';
import { useRequest } from 'hooks/socket';
import { MediaTrack } from 'hooks/jitsi/types';
import {
  useCurrentPresent,
  usePresentControls,
  useLayoutToolbar,
  useKeyboardInput,
  usePresentAnalytics,
} from './layoutHooks';
import PresenterHeader from './PresenterHeader';
import Placeholder from './Placeholder';

import styles from './PresenterLayout.module.scss';

interface PresenterLayoutProps {
  meet: Meeting;
  isSelfPointer: boolean;
  currentSlideId: ID | null;
  currentPresentationId: ID | null;
  setPresentId: (id: ID) => void;
  setSlideId: (id: ID) => void;
  screenShare?: MediaTrack;
}

const PresenterLayout: React.FC<PresenterLayoutProps> = ({
  meet,
  isSelfPointer,
  currentSlideId,
  currentPresentationId,
  setPresentId,
  setSlideId,
  screenShare,
}) => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const { currentPresentation, currentSlideIndex, currentSlide, currentSlides } = useCurrentPresent(
    {
      detailingEnv: 'rd',
      meet,
      currentSlideId,
      currentPresentationId,
    },
  );
  const { isFullscreenEnabled, isFullscreen, onFullscreen, zoom, setZoom } = useLayoutToolbar({
    layoutRef,
  });
  const { goTo, goNext, goBack } = usePresentControls({
    meet,
    currentPresentation,
    setSlideId,
    setPresentId,
    currentSlides,
    current: currentSlideIndex + 1,
  });
  const { onFrameClick } = usePresentAnalytics(
    {
      currentPresentationId,
      currentSlideId,
    },
    { isTrackSlideChange: true },
  );

  const slideRequest = useRequest<{ slideId: ID; presentationId: ID }, { status: number }>(
    'meet.setSlide',
  );

  useEffect(() => {
    if (!currentSlideId || !currentPresentationId) {
      return;
    }

    slideRequest({
      slideId: currentSlideId,
      presentationId: currentPresentationId,
    }).catch((err) => {
      console.warn('PresenterLayout: unhandled errors on slideRequest!', err);
    });
  }, [currentSlideId, currentPresentationId, slideRequest]);

  useKeyboardInput({
    goNext,
    goBack,
  });

  return (
    <div ref={layoutRef} className={styles.layout}>
      <PresenterHeader
        isShowFullscreen={isFullscreenEnabled}
        isFullscreen={isFullscreen}
        onFullscreen={onFullscreen}
        slideCounts={currentSlides?.length}
        currentSlide={currentSlideIndex + 1}
        goTo={goTo}
        presentations={meet.presentations}
        currentPresentation={currentPresentation}
        setCurrentPresent={setPresentId}
        zoom={zoom}
        setZoom={setZoom}
      />
      {screenShare ? (
        <VideoCanvas video={screenShare} zoom={zoom} />
      ) : !meet.presentations.length ? (
        <Placeholder />
      ) : (
        currentPresentation &&
        currentSlideId &&
        currentSlide &&
        currentSlides && (
          <>
            <Canvas
              title={currentPresentation.name}
              isPointer={isSelfPointer}
              slide={currentSlide}
              zoom={zoom}
              orientation={
                isPresentation(currentPresentation) ? currentPresentation.orientation : undefined
              }
              onFrameClick={onFrameClick}
            />
            <SlidesPreview
              slides={currentSlides}
              currentSlideId={currentSlideId}
              onSlide={setSlideId}
            />
          </>
        )
      )}
    </div>
  );
};

export default PresenterLayout;
