import { useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { add } from 'date-fns';

import { useEvent, useRequest } from 'hooks/socket';
import PresenterLayout from 'components/Present/PresenterLayout';
import ViewerLayout from 'components/Present/ViewerLayout';
import Conference from 'components/Conference';
import WebViewConference from 'components/WebViewConference';
import Waiting from 'components/Waiting';
import HideButton from 'ui/HideButton';
import { AppDispatch } from 'store';
import { Meeting, MeetingInfo, ID, User, Timestamp } from 'store/models';
import { getCurrentUser, getFlag } from 'store/selectors';
import useJitsi, { useParseTracks, Provider as JitsiProvider } from 'hooks/jitsi';
import useTimer from 'hooks/useTimer';
import PublicSdkProvider from 'sdk/Provider';
import { useAnalytic } from 'hooks/analytic';

import styles from './PresentLayout.module.scss';

interface PresentLayoutProps {
  meet: Meeting;
  userPointers: Set<ID>;
  isConferenceOnly?: boolean;
  isPresentOnly?: boolean;
  currentSlideId: ID | null;
  focusUserId: null | ID;
  setFocusUserId: (id: null | ID) => void;
  presenterLeaveAt: null | Timestamp;
  currentPresentationId: ID | null;
  isRecord?: boolean;
  setIsRecord: (val: boolean) => void;
  roomId: string;
  mediaToken: string;
  onSetSlide: (id: ID) => void;
  onSetPresent: (id: ID) => void;
  onPresentInfo: (presentId: ID, slideID: ID) => void;
  onUpdateMeet: (meet: Meeting) => void;
  onUpdateMeetInfo: (meet: MeetingInfo) => void;
  onSetLikes: (val: boolean) => void;
  onSetPointer: ({ userId, value }: { userId: ID; value: boolean }) => void;
  onExit: () => void;
  onFinish: () => void;
}

const PresentLayout: React.FC<PresentLayoutProps> = ({
  meet,
  userPointers,
  isConferenceOnly = false,
  isPresentOnly = false,
  isRecord = false,
  focusUserId,
  setFocusUserId,
  setIsRecord,
  currentSlideId,
  presenterLeaveAt,
  currentPresentationId,
  roomId,
  mediaToken,
  onSetSlide,
  onSetPresent,
  onPresentInfo,
  onUpdateMeet,
  onUpdateMeetInfo,
  onSetLikes,
  onSetPointer,
  onExit,
  onFinish,
}) => {
  const currentUser = useSelector(getCurrentUser);
  const isSelfPointer = useSelector(getFlag('isSelfPointer'));
  const isConferenceOpen = useSelector(getFlag('isConferenceOpen'));
  const isConferenceFullScreen = useSelector(getFlag('isConferenceFullScreen'));
  const trackEvent = useAnalytic();
  const rootDispatch = useDispatch<AppDispatch>();
  const isPresenter = useMemo(
    () => currentUser?._id === meet.presenter?._id,
    [currentUser?._id, meet.presenter?._id],
  );
  const { room, tracks, requestDevices, connectionState } = useJitsi({
    roomId,
    token: isPresentOnly ? null : mediaToken,
  });
  const parsedTracks = useParseTracks(
    tracks,
    currentUser?._id === focusUserId ? 'currentUser' : focusUserId,
  );
  const presenterLeaveAtTimeout = useMemo(
    () =>
      !presenterLeaveAt
        ? null
        : add(new Date(presenterLeaveAt), { seconds: meet.config.sessionTimeout }),
    [meet.config.sessionTimeout, presenterLeaveAt],
  );
  const [isPresenterLeave] = useTimer(presenterLeaveAtTimeout);

  useEffect(() => {
    if (room && currentUser?.name) {
      room.setDisplayName(currentUser.name);
    }
  }, [room, currentUser?.name]);

  useEvent('meet-update', ({ meet }: { meet: Meeting }) => {
    onUpdateMeet(meet);
  });

  useEvent('meet-info', ({ meetInfo }: { meetInfo: MeetingInfo }) => {
    onUpdateMeetInfo(meetInfo);
  });

  useEvent('conference-info', ({ users }: { users: User[] }) => {
    rootDispatch({ type: 'users', users });
  });

  useEvent('conference-enter', ({ user }: { user: User }) => {
    rootDispatch({ type: 'users', users: [user] });
  });

  const finishMeet = useRequest<{ meet: Partial<Meeting> }, { status: number }>('meet.end');
  const handleFinish = useCallback(() => {
    if (isPresenter) {
      finishMeet();
      onFinish();
    } else {
      onExit();
    }
  }, [finishMeet, onExit, onFinish, isPresenter]);

  const chatPost = useRequest<{ text: string; distingusher?: string }, { status: number }>(
    'chat.post',
  );
  const handleMessage = useCallback(
    async ({ text }: { text: string }) => {
      try {
        const distingusher = `chat-message-${Math.random()}-${Math.random()}`;

        await chatPost({ text, distingusher });
      } catch (e) {
        console.log('new message error', e);
      }
    },
    [chatPost],
  );

  if (!meet.presenter || isPresenterLeave) {
    return <Waiting meet={meet} />;
  }

  return (
    <PublicSdkProvider>
      <div className={styles.layout}>
        {!isConferenceOnly && (
          <main
            className={cn(
              styles.present,
              isConferenceFullScreen && !isPresentOnly && styles.presentHidden,
            )}
          >
            {isPresenter ? (
              <PresenterLayout
                meet={meet}
                screenShare={parsedTracks.screenShare}
                currentSlideId={currentSlideId}
                currentPresentationId={currentPresentationId}
                setPresentId={onSetPresent}
                setSlideId={onSetSlide}
                isSelfPointer={isSelfPointer}
              />
            ) : (
              <ViewerLayout
                meet={meet}
                screenShare={parsedTracks.screenShare}
                currentSlideId={currentSlideId}
                currentPresentationId={currentPresentationId}
                setPresentInfo={onPresentInfo}
                isPointer={currentUser ? userPointers.has(currentUser?._id) : false}
              />
            )}
          </main>
        )}
        {isPresentOnly ? (
          <WebViewConference meet={meet} onFinish={handleFinish} onMessage={handleMessage} />
        ) : (
          <aside
            className={cn(
              styles.conference,
              isConferenceOnly && styles.conferenceFullScreen,
              !isConferenceOpen && styles.conferenceIsClose,
              isConferenceFullScreen && styles.conferenceFullScreen,
            )}
          >
            <JitsiProvider room={room}>
              <Conference
                isPresenter={isPresenter}
                connectionState={connectionState}
                tracks={parsedTracks.conference}
                meetId={roomId}
                isRecord={isRecord}
                focusUserId={focusUserId}
                setFocusUserId={setFocusUserId}
                isRecordable={true || meet.config.isRecords}
                setIsRecord={setIsRecord}
                userPointers={userPointers}
                isSelfPointer={isSelfPointer}
                isLikes={meet.isLikes}
                activeDevices={parsedTracks.activeDevices}
                requestDevices={requestDevices}
                setLikes={onSetLikes}
                setPointer={onSetPointer}
                isChat={meet.config.isChat}
                isVideoAble={meet.config.isVideo}
                setSelfPointer={(value) =>
                  rootDispatch({ type: 'setFlag', name: 'isSelfPointer', value })
                }
                onFinish={handleFinish}
                onMessage={handleMessage}
              />
            </JitsiProvider>
            {!isConferenceOnly && (
              <div>
                <HideButton
                  direction="left"
                  color="light"
                  isOpen={isConferenceOpen}
                  className={styles.conferenceHideButton}
                  onClick={() =>
                    rootDispatch({
                      type: 'setFlag',
                      name: 'isConferenceOpen',
                      value: !isConferenceOpen,
                    })
                  }
                />
                <HideButton
                  direction="right"
                  color="dark"
                  isOpen={!isConferenceFullScreen}
                  className={styles.conferenceHideButtonReverse}
                  onClick={() => {
                    rootDispatch({
                      type: 'setFlag',
                      name: 'isConferenceFullScreen',
                      value: !isConferenceFullScreen,
                    });
                    if (!isConferenceFullScreen) {
                      trackEvent({ event: 'full_screen_conference', payload: {} });
                    }
                  }}
                />
              </div>
            )}
          </aside>
        )}
      </div>
    </PublicSdkProvider>
  );
};

export default PresentLayout;
