import { Link } from 'react-router-dom';

import styles from './Pages.module.scss';

const NotFound: React.FC = () => (
  <div className={styles.placeholderPage}>
    <div>
      404 - Page not found
      <br />
      <Link replace to="/">
        Go Home
      </Link>
    </div>
  </div>
);

export default NotFound;
