import { useCallback } from 'react';
import cn from 'classnames';

import { useAnalytic } from 'hooks/analytic';

import styles from './SlidePager.module.scss';

import { ReactComponent as CarpetIcon } from 'assets/CarpetIcon.svg';

interface SlidePagerProps {
  current: number;
  count: number;
  goTo: (e: number) => void;
}

const SlidePager: React.FC<SlidePagerProps> = ({ current, count, goTo }) => {
  const trackEvent = useAnalytic();

  const handleNext = useCallback(() => {
    if (current < count) {
      goTo(current + 1);
      trackEvent({ event: 'buttons_slide_view', payload: {} });
    }
  }, [current, count, goTo, trackEvent]);

  const handleBack = useCallback(() => {
    if (current > 1) {
      goTo(current - 1);
      trackEvent({ event: 'buttons_slide_view', payload: {} });
    }
  }, [current, goTo, trackEvent]);

  return (
    <div className={styles.slider}>
      <button
        disabled={current <= 1}
        className={cn(styles.sliderButton, styles.sliderButtonNext)}
        onClick={handleBack}
      >
        <CarpetIcon />
      </button>
      <span className={styles.sliderText}>
        <span className={styles.sliderTextCurrent}>{current}</span> / {count}
      </span>
      <button
        disabled={current >= count}
        className={cn(styles.sliderButton, styles.sliderButtonBack)}
        onClick={handleNext}
      >
        <CarpetIcon />
      </button>
    </div>
  );
};

export default SlidePager;
