import { useEffect, useContext, useCallback } from 'react';

import useEventHandler from '../useEventHandler';
import Provider, { SocketContext } from './Provider';

export { Provider };

export function useEvent<T extends (...args: any[]) => any>(name: string, cb: T) {
  const socket = useContext(SocketContext);
  const evCb = useEventHandler(cb);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on(name, evCb);

    return () => {
      socket.off(name, evCb);
    };
  }, [name, evCb, socket]);
}

export function useRequest<T, R>(requset: string) {
  const socket = useContext(SocketContext);

  return useCallback(
    (payload?: T) => {
      return new Promise<R>((resolve, reject) => {
        if (!socket) {
          return reject(new Error('Socket connection not ready yet!'));
        }

        socket.emit(requset, payload, (data: R) => {
          resolve(data);
        });
      });
    },
    [requset, socket],
  );
}

export function useBroadcast<T>(event: string) {
  const socket = useContext(SocketContext);

  return useCallback(
    (payload?: T) => {
      if (!socket) {
        console.warn('Socket connection not ready yet!');

        return;
      }

      socket.emit(event, payload);
    },
    [event, socket],
  );
}
