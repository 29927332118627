import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import qs from 'qs';

import { AppDispatch } from 'store';
import { userJoin, userJoinViaService, userJoinViaDirect } from 'store/actions';
import { getLoggedIn, getToken } from 'store/selectors';
import useAudioContext from 'hooks/audio';
import useAwake from 'hooks/useAwake';
import Spinner from 'ui/Spinner';
import { stringyfyQuery } from 'utils/cookie';

import EnterMeet from './EnterMeet';
import Login from './Login';
import PresentView from './PresentView';

interface PresentProps {
  isPresentOnly?: boolean;
  isConferenceOnly?: boolean;
}

const Present: React.FC<PresentProps> = ({ isPresentOnly = false, isConferenceOnly = false }) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector(getLoggedIn);
  const token = useSelector(getToken);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [isEntered, setEnter] = useState(false);
  useAwake(!isPresentOnly);
  const { resume } = useAudioContext();

  const handleEnter = useCallback(
    () =>
      (isPresentOnly ? Promise.resolve() : resume()).finally(() => {
        setEnter(true);
      }),
    [resume, isPresentOnly],
  );

  const handleUser = useCallback(
    (user: { email: string; name: string }) => {
      handleEnter()
        .then(() => dispatch(userJoin(user)))
        .catch((err) => {
          console.log('jwt is not ready', err);
        });
    },
    [dispatch, handleEnter],
  );

  useEffect(() => {
    const query = qs.parse(window.location.search.replace(/^\?/, ''));

    if (query.access_token && query.refresh_token && query.instance_url) {
      setLoading(true);

      dispatch(
        userJoinViaDirect({
          access_token: stringyfyQuery(query.access_token),
          refresh_token: stringyfyQuery(query.refresh_token),
          instance_url: stringyfyQuery(query.instance_url),
        }),
      ).finally(() => {
        setLoading(false);
      });
    }

    if (query.t) {
      setLoading(true);
      const token = stringyfyQuery(query.t);

      dispatch(
        userJoinViaService({
          token,
        }),
      )
        .then(() => {
          history.replace(window.location.pathname);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, history]);

  if (isLoading) {
    return <Spinner isCenter />;
  }

  if (isLoggedIn) {
    if (!isEntered) {
      return <EnterMeet onEnter={handleEnter} />;
    }

    return (
      <PresentView
        roomId={id}
        token={token as string}
        isPresentOnly={isPresentOnly}
        isConferenceOnly={isConferenceOnly}
      />
    );
  }

  return <Login entityType="room" entityId={id} onUser={handleUser} />;
};

export default Present;
