import { useRef, useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Meeting, ID, Comment as CommentModel, isPresentation } from 'store/models';
import {
  useCurrentPresent,
  useLayoutToolbar,
  usePresentControls,
  useKeyboardInput,
  useVotings,
  usePresentAnalytics,
} from './layoutHooks';
import Canvas from './Canvas';
import SelfDetailingFooter from './SelfDetailingFooter';
import Comments from '../Comments';

import fromRightSide from 'ui/transitions/FromRightSide.module.scss';
import styles from './PresenterLayout.module.scss';

interface SelfDetailingLayoutProps {
  meet: Meeting;
  currentSlideId: ID | null;
  currentPresentationId: ID | null;
  setPresentId: (id: ID) => void;
  setSlideId: (id: ID) => void;
}

const SelfDetailingLayout: React.FC<SelfDetailingLayoutProps> = ({
  meet,
  currentSlideId,
  currentPresentationId,
  setPresentId,
  setSlideId,
}) => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef(null);
  const { isFullscreen, isFullscreenEnabled, onFullscreen, zoom, setZoom } = useLayoutToolbar({
    layoutRef,
  });

  const { currentPresentation, currentSlide, currentSlideIndex, currentSlides } = useCurrentPresent(
    {
      detailingEnv: 'sd',
      meet,
      currentPresentationId,
      currentSlideId,
    },
  );

  const { goTo, goNext, goBack } = usePresentControls({
    meet,
    currentPresentation,
    setSlideId,
    setPresentId,
    currentSlides,
    current: currentSlideIndex + 1,
  });

  const { onFrameClick } = usePresentAnalytics(
    {
      currentSlideId,
      currentPresentationId,
    },
    { isTrackSlideChange: true },
  );

  useKeyboardInput({
    goNext,
    goBack,
  });

  const { voteTo, handleVote } = useVotings({
    cacheKey: `sd-${meet._id}`,
    currentPresentationId,
    currentSlideId,
  });

  const [isComments, setIsComments] = useState(false);
  const toggleComments = useCallback(() => {
    setIsComments((val) => !val);
  }, []);

  const [comments, setComments] = useState<{ [key: string]: CommentModel[] }>({});
  const handleComment = useCallback(
    ({ text }) => {
      if (!currentSlideId) {
        return;
      }

      const newComment = {
        text,
        _id: `${Math.random()}`,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      setComments((comm) => ({
        ...comm,
        [currentSlideId]: [...(comm[currentSlideId] ?? []), newComment],
      }));
    },
    [currentSlideId],
  );

  return (
    <div ref={layoutRef} className={styles.layout}>
      {currentPresentation && currentSlide && (
        <>
          <Canvas
            isPadded={false}
            isProxyEvents={false}
            color="white"
            isPointer
            orientation={
              isPresentation(currentPresentation) ? currentPresentation.orientation : undefined
            }
            title={currentPresentation.name}
            slide={currentSlide}
            zoom={zoom}
            onFrameClick={onFrameClick}
          />
          <SelfDetailingFooter
            goTo={goTo}
            isShowFullscreen={isFullscreenEnabled}
            isFullscreen={isFullscreen}
            onFullscreen={onFullscreen}
            setZoom={setZoom}
            zoom={zoom}
            slideCounts={currentSlides?.length}
            currentSlide={currentSlideIndex + 1}
            presentations={meet.presentations}
            currentPresentation={currentPresentation}
            setCurrentPresent={setPresentId}
            isLikes={meet.isLikes}
            voteTo={voteTo}
            onVote={handleVote}
            isActiveComments={isComments}
            toggleComments={toggleComments}
          />
        </>
      )}
      <CSSTransition
        in={isComments}
        nodeRef={nodeRef}
        mountOnEnter
        unmountOnExit
        timeout={300}
        classNames={fromRightSide}
      >
        <Comments
          ref={nodeRef}
          className={styles.comments}
          onClose={toggleComments}
          comments={comments?.[currentSlideId || ''] ?? []}
          onAddComment={handleComment}
        />
      </CSSTransition>
    </div>
  );
};

export default SelfDetailingLayout;
