import { bridgeCreate, bridgeQuery, bridgeUpdate, bridgeDelete } from 'api';

import { Meeting, Slide, CurrentPresentation, isPresentation } from 'store/models';
import callbackify from 'utils/callbackify';
import notImplemented from 'utils/notImplemented';
import GlobalizebleObject from './GlobalizebleObject';

export type DetailingEnv = 'rd' | 'sd';

interface CtmSlide {
  branch?: string;
  id: string;
  order?: number;
  thumb: string;
  title?: string;
  url: string;
}

interface CtmAppInfo {
  slideCount: number;
  currentSlide?: CtmSlide;
  data: {
    appid?: string;
    str_appid?: string;
    info: string;
    isChatEnabled: boolean;
    isChatLogEnabled: boolean;
    isDetailedStatsEnabled: boolean;
    isVideoEnabled: boolean;
    isCallRepeatAllowed: boolean;
    IsCallRepeatAllowed: boolean;
    is_vertical: boolean;
    slides: CtmSlide[];
    statSettings?: {
      isSlideClicksTrackingEnabled: boolean;
      isSlideDurationTrackingEnabled: boolean;
      isSlideLikesTrackingEnabled: boolean;
      isSlideNavigationTrackingEnabled: boolean;
      isSlideViewsTrackingEnabled: boolean;
      isTotalDurationTrackingEnabled: boolean;
    };
    scenarios: {
      appid: string;
      combined: boolean;
      description: string;
      favorite: boolean;
      id: string;
      name: string;
      slides: CtmSlide[];
    }[];
  };
}

function createSlideFromState(slide: Slide): CtmSlide {
  return {
    branch: slide.branch,
    id: slide.externalId,
    order: slide.order,
    thumb: slide.preview.small,
    title: slide.title,
    url: slide.url,
  };
}

class Ctm extends GlobalizebleObject {
  create: ReturnType<Ctm['bridgeRequst']>;
  query: ReturnType<Ctm['bridgeRequst']>;
  update: ReturnType<Ctm['bridgeRequst']>;
  delete: ReturnType<Ctm['bridgeRequst']>;
  updateSync: (...args: any) => any;
  _getAppState: () => {
    detailingEnv: DetailingEnv;
    meet: Meeting;
    currentSlide?: Slide;
    currentPresentation?: CurrentPresentation;
  };
  _trackEvent: () => void;

  constructor({
    getAppState,
    trackEvent,
  }: {
    getAppState: Ctm['_getAppState'];
    trackEvent: Ctm['_trackEvent'];
  }) {
    super();

    this._trackEvent = trackEvent;
    this.create = this.bridgeRequst(bridgeCreate);
    this.query = this.bridgeRequst(bridgeQuery);
    this.update = this.bridgeRequst(bridgeUpdate);
    this.delete = this.bridgeRequst(bridgeDelete);

    (
      ['create', 'update', 'delete', 'query', 'getAppInfo', 'getVisitId', 'getAppEnv'] as const
    ).forEach((key) => {
      // @ts-ignore
      this[key] = callbackify(this[key]);
    });

    (
      [
        'like',
        'dislike',
        'sync',
        'photo',
        'generatePDF',
        'openDataTableByParentRecord',
        'openDataTableByQuery',
        'openQuiz',
        'openQRScanner',
      ] as const
    ).forEach((key) => {
      this[key] = notImplemented(key);
    });

    this.updateSync = this.update;
    this._getAppState = getAppState;
  }

  bridgeRequst(requester: typeof bridgeQuery) {
    return async (payload: string) => {
      const state = this._getAppState();

      if (state.detailingEnv === 'sd') {
        throw new Error('CTM Bridge requests restricted for self detailing!');
      }

      const meetId = state.meet._id;
      const visitId = this.getVisitId();
      if (payload) {
        // TODO: add custom activity object to parse
        // example: options[`{!${room.visit_object}.Id}`] = room.visit_id || '000000000000000';
        payload = payload.replace('{!Activity.Id}', visitId);
      }

      this._trackEvent();

      return (await requester({ meetId, payload })).data;
    };
  }

  getAppEnv(): DetailingEnv {
    return this._getAppState().detailingEnv;
  }

  getAppInfo(): CtmAppInfo {
    const { meet, currentSlide, currentPresentation } = this._getAppState();
    const present = isPresentation(currentPresentation)
      ? currentPresentation
      : meet.presentations.find((p) => p._id === currentPresentation?.presentation);

    return {
      slideCount: currentPresentation?.slides.length ?? 0,
      currentSlide: currentSlide && createSlideFromState(currentSlide),
      data: {
        info: '', // backport capability
        appid: present?.externalId,
        str_appid: present?.alias,
        slides: present?.slides.map(createSlideFromState) ?? [],
        is_vertical: present?.orientation === 'vertical',
        isChatEnabled: meet.config.isChat,
        isChatLogEnabled: meet.config.isChatHistory,
        isDetailedStatsEnabled: meet.config.isDetailedStats,
        isVideoEnabled: meet.config.isVideo,
        isCallRepeatAllowed: meet.config.isCallRepeat,
        IsCallRepeatAllowed: meet.config.isCallRepeat, // backport capability
        statSettings: present && {
          isSlideClicksTrackingEnabled: present.analytics.slideClicks,
          isSlideDurationTrackingEnabled: present.analytics.slideDuration,
          isSlideLikesTrackingEnabled: present.analytics.votings,
          isSlideNavigationTrackingEnabled: present.analytics.slideNavigation,
          isSlideViewsTrackingEnabled: present.analytics.slideViews,
          isTotalDurationTrackingEnabled: present.analytics.totalDuration,
        },
        scenarios:
          present?.scenarios.map((scen) => ({
            id: scen.scenarioId,
            description: '', // backport capability
            appid: scen.presentation,
            combined: scen.isCombined,
            favorite: scen.isFavorite,
            name: scen.name,
            slides: scen.slides.map(createSlideFromState),
          })) ?? [],
      },
    };
  }

  getVisitId(): string {
    return this._getAppState().meet.visitId;
  }

  // Not implemented
  like() {}
  dislike() {}
  sync() {}
  photo() {}
  generatePDF() {}
  openDataTableByParentRecord(data: any) {}
  openDataTableByQuery(data: any) {}
  openQuiz(data: any) {}
  openQRScanner(data: any) {}
}

export default Ctm;
