import { Component } from 'react';
import LoginLayout from 'components/LoginLayout';
import Button from 'ui/Button';

import styles from 'components/Waiting.module.scss';

interface ErrorBoundaryState {
  error: null | { err: Error | null; info: object };
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
  };

  componentDidCatch(err: Error | null, info: object) {
    this.setState({
      error: {
        err,
        info,
      },
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <LoginLayout isLogo>
          <div className={styles.wrapper}>
            <h2>Ooops! Unhandled error happened!</h2>
            <p>
              Try to click <b>"Refresh"</b> button below or contact support if this happens again.
            </p>
            <Button color="secondary" size="sm" onClick={() => window.location.reload()}>
              Refresh
            </Button>
          </div>
          {error.err && (
            <details>
              <summary>Error details</summary>
              <div>{error.err?.toString()}</div>
            </details>
          )}
        </LoginLayout>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
