import { useMemo, forwardRef, memo } from 'react';
import { useSelector } from 'react-redux';

import { getUserCreds, getUserName } from 'store/selectors';
import { ID } from 'store/models';
import colorFromId from 'utils/colorFromId';

import styles from './Canvas.module.scss';

import { ReactComponent as PointerIcon } from 'assets/PointerIcon.svg';

interface CursorProps {
  userId: ID;
  coords: { x: number; y: number };
}

const Cursor = forwardRef<HTMLDivElement, CursorProps>(({ userId, coords }, ref) => {
  const userColor = useMemo(() => colorFromId(userId), [userId]);
  const userName = useSelector(getUserName(userId));
  const creds = useSelector(getUserCreds(userId));

  return (
    <div
      ref={ref}
      className={styles.pointer}
      title={userName}
      style={{
        transform: `translate3d(${coords.x}px, ${coords.y}px, 0)`,
        color: userColor,
      }}
    >
      <PointerIcon />
      <span className={styles.pointerCaption}>
        <span className={styles.pointerText}>{creds}</span>
      </span>
    </div>
  );
});

export default memo(Cursor);
